import React from 'react';
import { Redirect } from 'react-router';
import { Router } from 'react-router-dom';

import Documentos from 'matilda/pages/documentos/Documentos.js';
import Peticiones from 'matilda/pages/peticiones/Peticiones.js';
import Evaluaciones from 'matilda/pages/evaluaciones/Evaluaciones.js';
import LoginMatilda from 'matilda/pages/seguridad/Login-Matilda.js';
import RegisterV3 from 'matilda/pages/seguridad/register-v3.js';
import LoginV3 from 'matilda/pages/seguridad/login-v3.js';
import ConfiguracionMotores from 'matilda/pages/config/ConfiguracionMotores.js';
import TiposProcedimientos from 'matilda/pages/tiposprocedimientos/TiposProcedimientos.js';
import TiposMotores from 'matilda/pages/tiposmotores/TiposMotores.js';
import Diccionario from 'matilda/pages/diccionario/Diccionario.js';
import Reglas from 'matilda/pages/reglas/Reglas.js';
import Parametros from 'matilda/pages/parametros/Parametros.js';
import Hitos from 'matilda/pages/hitos/Hitos.js';
import Iteraciones from 'matilda/pages/iteraciones/Iteraciones.js';

import DashboardV1 from 'color_admin/pages/dashboard/dashboard-v1.js';
import DashboardV2 from 'color_admin/pages/dashboard/dashboard-v2.js';
import DashboardV3 from 'color_admin/pages/dashboard/dashboard-v3.js';
import EmailInbox from 'color_admin/pages/email/email-inbox.js';
import EmailCompose from 'color_admin/pages/email/email-compose.js';
import EmailDetail from 'color_admin/pages/email/email-detail.js';
import Widgets from 'color_admin/pages/widget/widget.js';
import UIGeneral from 'color_admin/pages/ui/ui-general.js';
import UITypography from 'color_admin/pages/ui/ui-typography.js';
import UITabsAccordion from 'color_admin/pages/ui/ui-tabs-accordion.js';
import UIModalNotification from 'color_admin/pages/ui/ui-modal-notification.js';
import UIWidgetBoxes from 'color_admin/pages/ui/ui-widget-boxes.js';
import UIMediaObject from 'color_admin/pages/ui/ui-media-object.js';
import UIButtons from 'color_admin/pages/ui/ui-buttons.js';
import UIIcons from 'color_admin/pages/ui/ui-icons.js';
import UISimpleLineIcons from 'color_admin/pages/ui/ui-simple-line-icons.js';
import UIIonicons from 'color_admin/pages/ui/ui-ionicons.js';
import UILanguageBarIcon from 'color_admin/pages/ui/ui-language-bar-icon.js';
import UISocialButtons from 'color_admin/pages/ui/ui-social-buttons.js';
import Bootstrap4 from 'color_admin/pages/bootstrap/bootstrap-4.js';
import FormElements from 'color_admin/pages/form/form-elements.js';
import FormPlugins from 'color_admin/pages/form/form-plugins.js';
import TableBasic from 'color_admin/pages/table/table-basic.js';
import TableData from 'color_admin/pages/table/table-data.js';
import ChartJS from 'color_admin/pages/chart/chart-js.js';
import ChartD3 from 'color_admin/pages/chart/chart-d3.js';
import ChartApex from 'color_admin/pages/chart/chart-apex.js';
import Calendar from 'color_admin/pages/calendar/calendar.js';
import Map from 'color_admin/pages/map/map.js';
import Gallery from 'color_admin/pages/gallery/gallery.js';
import PageBlank from 'color_admin/pages/option/page-blank.js';
import PageWithFooter from 'color_admin/pages/option/page-with-footer.js';
import PageWithoutSidebar from 'color_admin/pages/option/page-without-sidebar.js';
import PageWithRightSidebar from 'color_admin/pages/option/page-with-right-sidebar.js';
import PageWithMinifiedSidebar from 'color_admin/pages/option/page-with-minified-sidebar.js';
import PageWithTwoSidebar from 'color_admin/pages/option/page-with-two-sidebar.js';
import PageFullHeight from 'color_admin/pages/option/page-full-height.js';
import PageWithWideSidebar from 'color_admin/pages/option/page-with-wide-sidebar.js';
import PageWithLightSidebar from 'color_admin/pages/option/page-with-light-sidebar.js';
import PageWithMegaMenu from 'color_admin/pages/option/page-with-mega-menu.js';
import PageWithTopMenu from 'color_admin/pages/option/page-with-top-menu.js';
import PageWithBoxedLayout from 'color_admin/pages/option/page-with-boxed-layout.js';
import PageWithMixedMenu from 'color_admin/pages/option/page-with-mixed-menu.js';
import PageBoxedLayoutWithMixedMenu from 'color_admin/pages/option/page-boxed-layout-with-mixed-menu.js';
import PageWithTransparentSidebar from 'color_admin/pages/option/page-with-transparent-sidebar.js';
import PageWithSearchSidebar from 'color_admin/pages/option/page-with-search-sidebar.js';
import ExtraTimeline from 'color_admin/pages/extra/extra-timeline.js';
import ExtraComingSoon from 'color_admin/pages/extra/extra-coming-soon.js';
import ExtraSearch from 'color_admin/pages/extra/extra-search.js';
import ExtraInvoice from 'color_admin/pages/extra/extra-invoice.js';
import ExtraError from 'color_admin/pages/extra/extra-error.js';
import ExtraProfile from 'color_admin/pages/extra/extra-profile.js';
import ExtraScrumBoard from 'color_admin/pages/extra/extra-scrum-board.js';
import ExtraCookieAcceptanceBanner from 'color_admin/pages/extra/extra-cookie-acceptance-banner.js';
import LoginV1 from 'color_admin/pages/user/login-v1.js';
import LoginV2 from 'color_admin/pages/user/login-v2.js';
//import LoginV3 from 'color_admin/pages/user/login-v3.js';
//import RegisterV3 from 'color_admin/pages/user/register-v3.js';
import HelperCSS from 'color_admin/pages/helper/helper-css.js';

const routes = [
  {
    path: '/',
    exact: true,
        component: () => <Redirect to='matilda/pages/seguridad/Login-Matilda'/>
    },
    {
        path: '/matilda/documentos',
        exact: false,
        title: 'Documentos',
        component: () => <Documentos />,
        needsAuth: true
    },
    {
        path: '/matilda/peticiones',
        exact: false,
        title: 'Peticiones',
        component: () => <Peticiones />,
        needsAuth: true
    },
    {
        path: '/matilda/evaluaciones',
        exact: false,
        title: 'Evaluaciones',
        component: () => <Evaluaciones />,
        needsAuth: true
    },
    {
        path: '/matilda/configuracion/motores',
        exact: false,
        title: 'Configuración Motores',
        component: () => <ConfiguracionMotores />,
        needsAuth: true
    },
    {
        path: '/matilda/tiposprocedimientos',
        exact: false,
        title: 'Tipos de Procedimientos',
        component: () => <TiposProcedimientos />,
        needsAuth: true
    },
    {
        path: '/matilda/tiposmotores',
        exact: false,
        title: 'Tipos de Motores',
        component: () => <TiposMotores />,
        needsAuth: true
    },
    {
        path: '/matilda/hitos',
        exact: false,
        title: 'Hitos',
        component: () => <Hitos />,
        needsAuth: true
    },
    {
        path: '/matilda/diccionario',
        exact: false,
        title: 'Diccionario',
        component: () => <Diccionario />,
        needsAuth: true
    },
    {
        path: '/matilda/reglas',
        exact: false,
        title: 'Reglas',
        component: () => <Reglas />,
        needsAuth: true
    },
    {
        path: '/matilda/parametros',
        exact: false,
        title: 'Parámetros',
        component: () => <Parametros />,
        needsAuth: true
    },
    {
        path: '/matilda/iteraciones',
        exact: false,
        title: 'Iteraciones',
        component: () => <Iteraciones />,
        needsAuth: true
    },
   {
    path: '/dashboard/v1',
    exact: true,
    title: 'Dashboard V1',
    component: () => <DashboardV1 />
  },
  {
    path: '/dashboard/v2',
    title: 'Dashboard V2',
    component: () => <DashboardV2 />
  },
  {
    path: '/dashboard/v3',
    title: 'Dashboard V3',
    component: () => <DashboardV3 />,
    needsAuth: true
  },
  {
    path: '/email/inbox',
    title: 'Email Inbox',
    component: () => <EmailInbox />,
  },
  {
    path: '/email/compose',
    title: 'Email Compose',
    component: () => <EmailCompose />,
  },
  {
    path: '/email/detail',
    title: 'Email Detail',
    component: () => <EmailDetail />,
  },
  {
    path: '/widgets',
    title: 'Widgets',
    component: () => <Widgets />,
  },
  {
    path: '/ui/general',
    title: 'UI General',
    component: () => <UIGeneral />,
  },
  {
    path: '/ui/typography',
    title: 'UI Typography',
    component: () => <UITypography />,
  },
  {
    path: '/ui/tabs-accordion',
    title: 'UI Tabs Accordion',
    component: () => <UITabsAccordion />,
  },
  {
    path: '/ui/modal-notification',
    title: 'UI Modal & Notification',
    component: () => <UIModalNotification />,
  },
  {
    path: '/ui/widget-boxes',
    title: 'UI Widget Boxes',
    component: () => <UIWidgetBoxes />,
  },
  {
    path: '/ui/media-object',
    title: 'UI Media Object',
    component: () => <UIMediaObject />,
  },
  {
    path: '/ui/buttons',
    title: 'UI Buttons',
    component: () => <UIButtons />,
  },
  {
    path: '/ui/icons',
    title: 'UI Icons',
    component: () => <UIIcons />,
  },
  {
    path: '/ui/simple-line-icons',
    title: 'UI Simple Line Icons',
    component: () => <UISimpleLineIcons />,
  },
  {
    path: '/ui/ionicons',
    title: 'UI Ionicons',
    component: () => <UIIonicons />,
  },
  {
    path: '/ui/language-bar-icon',
    title: 'UI General',
    component: () => <UILanguageBarIcon />,
  },
  {
    path: '/ui/social-buttons',
    title: 'UI Social Buttons',
    component: () => <UISocialButtons />,
  },
  {
    path: '/bootstrap-4',
    title: 'Bootstrap 4',
    component: () => <Bootstrap4 />,
  },
  {
    path: '/form/elements',
    title: 'Form Elements',
    component: () => <FormElements />,
  },
  {
    path: '/form/plugins',
    title: 'Form Plugins',
    component: () => <FormPlugins />,
  },
  {
    path: '/table/basic',
    title: 'Basic Tables',
    component: () => <TableBasic />,
  },
  {
    path: '/table/data',
    title: 'Data Tables',
    component: () => <TableData />,
  },
  {
    path: '/chart/js',
    title: 'Chart JS',
    component: () => <ChartJS />,
  },
  {
    path: '/chart/d3',
    title: 'd3 Chart',
    component: () => <ChartD3 />,
  },
  {
    path: '/chart/apex',
    title: 'Apex Chart',
    component: () => <ChartApex />,
  },
  {
    path: '/calendar',
    title: 'Calendar',
    component: () => <Calendar />,
  },
  {
    path: '/map',
    title: 'Map',
    component: () => <Map />,
  },
  {
    path: '/gallery',
    title: 'Gallery',
    component: () => <Gallery />,
  },
  {
    path: '/page-option/blank',
    title: 'Blank Page',
    component: () => <PageBlank />,
  },
  {
    path: '/page-option/with-footer',
    title: 'Page with Footer',
    component: () => <PageWithFooter />,
  },
  {
    path: '/page-option/without-sidebar',
    title: 'Page without Sidebar',
    component: () => <PageWithoutSidebar />,
  },
  {
    path: '/page-option/with-right-sidebar',
    title: 'Page with Right Sidebar',
    component: () => <PageWithRightSidebar />,
  },
  {
    path: '/page-option/with-minified-sidebar',
    title: 'Page with Minified Sidebar',
    component: () => <PageWithMinifiedSidebar />,
  },
  {
    path: '/page-option/with-two-sidebar',
    title: 'PageWithTwoSidebar',
    component: () => <PageWithTwoSidebar />,
  },
  {
    path: '/page-option/full-height',
    title: 'Full Height Content',
    component: () => <PageFullHeight />,
  },
  {
    path: '/page-option/with-wide-sidebar',
    title: 'Page with Wide Sidebar',
    component: () => <PageWithWideSidebar />,
  },
  {
    path: '/page-option/with-light-sidebar',
    title: 'Page with Light Sidebar',
    component: () => <PageWithLightSidebar />,
  },
  {
    path: '/page-option/with-mega-menu',
    title: 'Page with Mega Menu',
    component: () => <PageWithMegaMenu />,
  },
  {
    path: '/page-option/with-top-menu',
    title: 'Page with Top Menu',
    component: () => <PageWithTopMenu />,
  },
  {
    path: '/page-option/with-boxed-layout',
    title: 'Page with Boxed Layout',
    component: () => <PageWithBoxedLayout />,
  },
  {
    path: '/page-option/with-mixed-menu',
    title: 'Page with Mixed Menu',
    component: () => <PageWithMixedMenu />,
  },
  {
    path: '/page-option/boxed-layout-with-mixed-menu',
    title: 'Boxed Layout with Mixed Menu',
    component: () => <PageBoxedLayoutWithMixedMenu />,
  },
  {
    path: '/page-option/with-transparent-sidebar',
    title: 'Page with Transparent Sidebar',
    component: () => <PageWithTransparentSidebar />,
  },
  {
    path: '/page-option/with-search-sidebar',
    title: 'Page with Search Sidebar',
    component: () => <PageWithSearchSidebar />,
  },
  {
    path: '/extra/timeline',
    title: 'Extra Timeline',
    component: () => <ExtraTimeline />,
  },
  {
    path: '/extra/coming-soon',
    title: 'Extra Coming Soon',
    component: () => <ExtraComingSoon />,
  },
  {
    path: '/extra/search',
    title: 'Extra Search Results',
    component: () => <ExtraSearch />,
  },
  {
    path: '/extra/invoice',
    title: 'Extra Invoice',
    component: () => <ExtraInvoice />,
  },
  {
    path: '/extra/error',
    title: 'Extra Error',
    component: () => <ExtraError />,
  },
  {
    path: '/extra/profile',
    title: 'Extra Profile',
    component: () => <ExtraProfile />,
  },
  {
    path: '/extra/scrum-board',
    title: 'Extra Scrum Board',
    component: () => <ExtraScrumBoard />,
  },
  {
    path: '/extra/cookie-acceptance-banner',
    title: 'Extra Cookie Acceptance Banner',
    component: () => <ExtraCookieAcceptanceBanner />,
  },
  {
    path: '/user/login-v1',
    title: 'Login',
    component: () => <LoginV1 />,
  },
  {
    path: '/user/login-v2',
    title: 'Login v2',
    component: () => <LoginV2 />,
  },
  /*
  {
    path: '/user/login-v3',
    title: 'Login v3',
    component: () => <LoginV3 />,
  },
  */
  {
    path: '/matilda/pages/seguridad/Login-Matilda',
    title: 'Login',
      component: () => <LoginMatilda />,
      needsAuth: false
    },
    {
        path: '/matilda/pages/seguridad/LoginV3',
        title: 'LoginV3',
        component: () => <LoginV3 />,
        needsAuth: false
    },
  /*
    {
        path: '/user/register-v3',
        title: 'Register v3',
        component: () => <RegisterV3 />,
    },
  */
    {
        path: '/user/register/item/0',
        title: 'RegisterV3',
        component: () => <RegisterV3 />,
        needsAuth: false
    },
  {
    path: '/helper/css',
    title: 'Predefined CSS Classes',
    component: () => <HelperCSS />,
  }
];


export default routes;

