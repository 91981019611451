import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter} from 'react-router-dom';
import Iteraciones_Item from './Iteraciones_Item';
import { Panel, PanelHeader, PanelBody } from 'color_admin/components/panel/panel.jsx';
import { ReactTableDefaults } from 'react-table';
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Button } from 'primereact/button';


/*sobrescribir el componente de paginación*/
import Pagination from 'comun/components/Pagination';
Object.assign(ReactTableDefaults, {
    PaginationComponent: Pagination
});


class Iteraciones extends Component {

    constructor() {
        super();

        this.linkItem = "/matilda/iteraciones/item"
        this.controller = "api/config/CalidadDocumentosTest";
        this.dt = undefined;

    
        this.state = {
            textoBusqueda: '',
            blnSoloSinReferencia: false,
            blnChecked: false,
            resultadosBusqueda: [],
            resultadoSeleccionado: null,
            sorted: [],
            page: 0,
            pageSize: 10,
            expanded: {},
            resized: [],
            filtered: [],
            totalSize:0
        };

    }

    actualizarTextoBusqueda(strTexto) {
        this.setState({ textoBusqueda: strTexto });
    };

    actualizarFiltroSinReferencia(blnValor) {
        this.setState({ blnSoloSinReferencia: blnValor });
    };

  
    seleccionarResultado(seleccion) {
        this.setState({ resultadoSeleccionado: seleccion });
        this.mostrarItem(seleccion["IntId"]);
    };

    mostrarItem(itemId) {
        this.props.history.push(this.linkItem + "/" + itemId);
    };

    async getResults() {
        this.loading = true;
        const response = await fetch(this.controller + '/filter?filter=' + this.state.textoBusqueda + '/' + this.state.blnSoloSinReferencia, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' +  localStorage.getItem('token')}
        });

        const data = await response.json();
        this.setState({ resultadosBusqueda: data });
        this.loading = false;

    }

    procesarBusqueda(key) {
        if (key === "Enter") {
            this.getResults();
        }
    }

    exportCSV() {
        if (this.dt !== undefined) {
            this.dt.exportCSV();
        }
    }    

    setDt(dt) {
        if (dt != null) {
            this.dt= dt;
        }
    }

        
    render() {
        
        const header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" label="Exportar" onClick={event => this.exportCSV()}></Button></div>;
        const booleanChecker = (rowData, item) => {
            if (typeof rowData[item.field] === "boolean") {
                return rowData[item.field] ? "Sí" : "No";
            } else {
                return rowData[item.field];
            }
        };
        return (
            
            <div className="contenedor" >
                 <div > 
                         <Panel>
                            <PanelHeader>Buscador de Iteraciones</PanelHeader>
                            <PanelBody>
                                <div className="input-group" >
                                    <input type="text" className="form-control" placeholder="Escriba un texto de búsqueda" value={this.state.textoBusqueda} onChange={event => this.actualizarTextoBusqueda(event.currentTarget.value)} />
                                    <div className="input-group-append align-items-center">
                                        <div className="d-flex ml-3 mr-3 align-items-center">
                                            <label for="checkbox1" className="text-muted form-check-label mr-1">Sin Referencia</label>
                                            <span><input type="checkbox" id="checkbox1" className="form-control" class="checkbox style-2 pull-right" value={this.state.blnSoloSinReferencia} onChange={event => this.actualizarFiltroSinReferencia(event.target.checked)} /></span>
                                        </div>
                                        <button type="button" className="btn btn-blue fa fa-search" onClick={event => this.getResults()} />
                                        <button label="Nuevo" className="btn btn-blue fa fa-plus" onClick={event => this.mostrarItem(0)} />
                                    </div>
                                    </div>
                               
                                <div className="m-t-5">
                                </div>

                                <DataTable value={this.state.resultadosBusqueda} ref={event => this.setDt(event)} className="p-datatable-striped" selection={this.state.resultadoSeleccionado} selectionMode="single" onSelectionChange={event => this.seleccionarResultado(event.value)} resizableColumns columnResizeMode="fit"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}" rows={10} rowsPerPageOptions={[5,10, 20, 50]}
                                    paginatorRight={header} scrollable scrollHeight="300px" sortMode="multiple" csvSeparator=";" >
                                    {/*<Column field="IntId" header="ID" style={{ width: '20%' }} sortable filter filterPlaceholder="Buscar ID"></Column>
                                    <Column field="DtmFecha" header='Fecha' sortable filter filterPlaceholder="Filtrar por Fecha" filterMatchMode="contains"></Column>*/}
                                    <Column field="IntIdPeticion" header='ID Peticion' style={{ width: '5%' }} sortable filter filterPlaceholder="Buscar ID Peticion"></Column>
                                    <Column field="StrIdentificadorTest" header='Identificador' sortable filter filterPlaceholder="Filtrar por Identificador" filterMatchMode="contains"></Column>
                                    <Column field="BlnResultadoOk" body={booleanChecker} header="Ok" sortable filter filterPlaceholder="Filtrar por Correctas" filterMatchMode="contains"></Column>
                                    <Column field="BlnEstado" body={booleanChecker} header='Estado' sortable filter filterPlaceholder="Filtrar por Estado" filterMatchMode="contains"></Column>
                                    <Column field="BlnRegla" body={booleanChecker} header="Regla" sortable filter filterPlaceholder="Filtrar por Regla" filterMatchMode="contains"></Column>
                                    <Column field="BlnReferenciaExterna" body={booleanChecker} header='Referencia Externa' sortable filter filterPlaceholder="Filtrar por score" filterMatchMode="contains"></Column>
                                    <Column field="BlnVoces" body={booleanChecker} header="Voces" sortable filter filterPlaceholder="Filtrar por Voces" filterMatchMode="contains"></Column>
                                    <Column field="BlnJsonVoces" body={booleanChecker} header='Json Voces' sortable filter filterPlaceholder="Filtrar por Json Voces" filterMatchMode="contains"></Column>
                                    <Column field="BlnCortePdf" body={booleanChecker} header="Corte pdf" sortable filter filterPlaceholder="Filtrar por Corte pdf" filterMatchMode="contains"></Column>
                                    {/*<Column field="BlnIgnorar" body={booleanChecker} header="Ignorar" sortable filter filterPlaceholder="Filtrar por Ignorar" filterMatchMode="contains"></Column>*/}
                                    <Column field="StrComentarios" header='Comentarios' sortable filter filterPlaceholder="Filtrar por Comentarios" filterMatchMode="contains"></Column>
                                    <Column field="IntIdCalidadDocumentosNavigation.StrComentarios" header='Com. Ref.' sortable filter filterPlaceholder="Comentarios Referencia" filterMatchMode="contains"></Column>
                                    <Column field="BlnRevisado" body={booleanChecker} header="Revisado" sortable filter filterPlaceholder="Filtrar por Revisado" filterMatchMode="contains"></Column>
                                    <Column field="BlnAlertaScore" body={booleanChecker} header='Alerta Score' sortable filter filterPlaceholder="Filtrar por score" filterMatchMode="contains"></Column>                                
                                </DataTable>
                                                         
     
                            </PanelBody>
                        </Panel>
                </div>

                <div className="rellenar" >
                    <Panel className="contenedor">
                        <PanelHeader>Iteraciones</PanelHeader>
                        <PanelBody className="rellenar" >
                                <Route path='/matilda/iteraciones/item/:itemId' component={Iteraciones_Item} />
                            </PanelBody>
                        </Panel>
                 </div>
             </div>
            

 
        );
    }

}


export default withRouter(Iteraciones)
