import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import Entity from 'comun/components/Entity';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';
import JsonEntity from 'comun/components/JsonEntity';
import JsonKey from 'comun/components/JsonKey';
import JsonValue from 'comun/components/JsonValue';



class Peticiones_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    

    render() {

         return (

             <Form controller='api/peticiones/Peticiones' title='Peticiones' entity='Peticiones' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>

                 <div className="row mb-1">
                     <div className="col-xl-4">
                         <label className="mt-2">Fecha</label>
                     </div>
                     <div className="col-xl-4">
                         <label className="mt-2">Referencia</label>
                     </div>
                     <div className="col-xl-4">
                         <label className="mt-2">Incluir en Test</label>
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-4">
                         {/*<DateField field='DtmFechaRecepcion' />*/}
                          <Field field='DtmFechaRecepcion' controlType='text' />
                     </div>
                     <div className="col-xl-4">
                         <Field field='StrReferencia' controlType='text' />
                     </div>
                     <div className="col-xl-4 pt-2 pl-4">
                         <BooleanField field='BlnIncluirTest' />
                     </div>
                        
                 </div>

                 <JsonEntity entity='JsonResultado'>
                     <div className="row mb-1">
                         <div className="col-xl-3">
                             <JsonKey />
                         </div>
                         <div className="col-xl-9">
                             <JsonValue />
                         </div>
                     </div>
                 </JsonEntity>

                 <Entity entity='IntIdDocumentoCabeceraNavigation' label='Cabecera'>
                     <div className="row mb-1">
                         <div className="col-xl-8">
                             <label className="mt-2">Cabecera</label>
                         </div>
                         <div className="col-xl-1">

                         </div>
                        
                     </div>
                     <div className="row mb-1">
                         <div className="col-xl-8">
                             <Field field='StrNombre' controlType='text' />
                         </div>
                         <div className="col-xl-1">
                             <UploadButton label="Importar" datosAdicionales="cabecera" className="btn btn btn-primary" />
                         </div>
 

                     </div>
                 </Entity>

                 <div/>
       
                 <Entity entity='IntIdDocumentoResolucionNavigation' label='Resolución'>
                     <div className="row mb-1">
                         <div className="col-xl-8">
                             <label className="mt-2">Resolución</label>
                         </div>
                         <div className="col-xl-1">

                         </div>
                         <div className="col-xl-2">
                             <label className="mt-2">Motor</label>
                         </div>
                     </div>
                     <div className="row mb-1">
                         <div className="col-xl-8">
                             <Field field='StrNombre' controlType='text' />
                         </div>
                         <div className="col-xl-1">
                             <UploadButton label="Importar" datosAdicionales="resolucion" className="btn btn btn-primary" />
                         </div>
                         <div className="col-xl-2">
                             <ComboField field='IntIdMotor' listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' enabled={ false}/>
                         </div>
                         <div className="col-xl-1">
                             <ActionButtonBackend label="Identificar" action="Identificando" className="btn btn btn-primary" />
                         </div>
                     </div>
                         <div className="row mb-1">
                             <div className="col-xl-12">
                                 <label>Párrafos</label>
                             </div>
                         </div>
                         
                         <EntityArray field='DocumentosSecciones' label='Detalles'>
                             <RowView>
                                 <div className="row mb-1">
                                     <div className="col-xl-11">
                                         <Field field='StrTexto' type='text' lineas={2} />
                                    </div>
                                    <div className="col-xl-1">
                                        <BooleanField field='BlnEncabezado' enabled={false}/>
                                    </div>
                               </div>
                             <div className="row mb-1">
                                 <div className="col-xl-12">
                                     <EntityArray field='DocumentosSeccionesSubsecciones' label='Subsecciones'>
                                         <RowView>
                                             <div className="row mb-1 mt-1">
                                                 <div className="col-xl-1">

                                                 </div>
                                                 <div className="col-xl-11">
                                                     <Field field='StrTexto' type='text' lineas={2} />
                                                 </div>
                                             </div>
                                         </RowView>
                                     </EntityArray>
                                 </div>
                             </div>

                                  
                                 <div className="row mb-1 ">
                                     <div className="col-xl-1">
                                     </div>
                                     <div className="col-xl-10">
                                         <EntityArray field='Identificaciones' label='Identificaciones' enabled={false}>
                                             <RowView>
                                                 <div className="border border-light rounded-sm mt-2 p-3">
                                                     <div className="row mb-1">
                                                         <div className="col-xl-1">
                                                             <label className="mt-2">Identificación</label>
                                                         </div>
                                                         <div className="col-xl-3">
                                                             <DateField field='DtmFecha' />
                                                         </div>
                                                         <div className="col-xl-1">
                                                             <label className="mt-2">Motor</label>
                                                         </div>
                                                         <div className="col-xl-7">
                                                             
                                                             <ComboField field='IntIdMotor' listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' enabled={false} />
                                                         </div>
                                                     </div>
                                                     <EntityArray field='IdentificacionesDetalles' label='Intenciones' enabled={false}>
                                                         <RowView>
                                                             <div className="border border-light rounded-sm mt-2 p-3">
                                                                 <div className="row mb-1">
                                                                     <div className="col-xl-1">
                                                                         <label className="mt-2">Intención</label>
                                                                     </div>
                                                                     <div className="col-xl-4">
                                                                         <ComboField field='StrClaseIntencion' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/intenciones' navigationPath='' placeHolder='Intención obtenida del motor de IA' />
                                                                     </div>
                                                                     <div className="col-xl-1">
                                                                         <label className="mt-2">Valores</label>
                                                                     </div>
                                                                     <div className="col-xl-6">
                                                                         <JsonEntity entity='JsonIntencion'>
                                                                             <div className="row mb-1">
                                                                                 <div className="col-xl-3">
                                                                                     <JsonKey />
                                                                                 </div>
                                                                                 <div className="col-xl-9">
                                                                                     <JsonValue />
                                                                                 </div>
                                                                             </div>
                                                                         </JsonEntity>
                                                                     </div>
                                                                 </div>

                                                             </div>
                                                         </RowView>
                                                     </EntityArray>

                                                 </div>
                                             </RowView>
                                         </EntityArray>
                                         
                                     </div>
                                     <div className="col-xl-1">
                                     </div>
                                 </div>
                                
                             </RowView>
                             
                         </EntityArray >
                     

                 </Entity>

                 <div />

                 <div className="row mb-1">
                     <div className="col-xl-12">
                         <label>Resultados</label>
                     </div>
                 </div>

                 <div className="border border-light rounded-sm mt-2 p-3">
                     <div className="row mb-1">
                         <div className="col-xl-1">
                             <label className="mt-2">Regla</label>
                         </div>
                         <div className="col-xl-5">
                             <Field field='StrReglaEncontrada' type='text' />
                         </div>
                         <div className="col-xl-1">
                             <label className="mt-2">Score</label>
                         </div>
                         <div className="col-xl-2">
                             <Field field='DblScoreRegla' type='text' />
                         </div>
                         <div className="col-xl-1">
                             <label className="mt-2">Referencia externa</label>
                         </div>
                         <div className="col-xl-2">
                             <Field field='StrExternalReference' type='text' />
                         </div>
                     </div>

                     <div className="row mb-1">
                         <div className="col-xl-12">
                             <label>Resumen</label>
                         </div>
                     </div>

                     <div className="row mb-1">
                         <div className="col-xl-12">
                             <Field field='StrResumenRegla' type='text' lineas={3} />
                         </div>
                     </div>

                     <div />

                     <div className="row mb-1">
                         <div className="col-xl-12">
                             <label>Voces</label>
                         </div>
                     </div>

                     <EntityArray field='PeticionesVoces' label='Voces'>
                         <RowView>

                             <div className="row mb-1">
                                 <div className="col-xl-1">
                                     <label className="mt-2">Voz</label>
                                 </div>
                                 <div className="col-xl-3">
                                     <ComboField field='StrClaseVoz' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/voces' navigationPath='' placeHolder='Declare la voz que desea añadir a la regla' forceValidation={true} enabled={false} />
                                 </div>
                                 <div className="col-xl-2">
                                     <label className="mt-2">Referencia Externa</label>
                                 </div>
                                 <div className="col-xl-2">
                                     <Field field='StrExternalReference' type='text' />
                                 </div>
                                 <div className="col-xl-4">
                                     <JsonEntity entity='JsonVoz'>
                                         <div className="row mb-1">
                                             <div className="col-xl-3">
                                                 <JsonKey />
                                             </div>
                                             <div className="col-xl-9">
                                                 <JsonValue />
                                             </div>
                                         </div>
                                     </JsonEntity>
                                 </div>
                             </div>
                         </RowView>

                     </EntityArray>

                 </div>
                 

                 

                 <ActionButtonBackend label="Analizar" action="analizando" className="btn btn btn-primary" />
            </Form>

        );
    }

}

export default withRouter(Peticiones_Item)
