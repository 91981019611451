
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PageSettings } from 'color_admin/config/page-settings.js'; 
import Field, { NumberField } from 'comun/components/Fields.js';
import ReactPlayer from "react-player";

class LoginMatilda extends React.Component {
	static contextType = PageSettings;

	constructor(props) {
		super(props);

		this.state = {
            activeBg: '/assets/color_admin/img/login-bg/login-bg-17.jpg',
			bg1: true,
			bg2: false,
			bg3: false,
			bg4: false,
			bg5: false,
            bg6: false,
            username:"",
            password:"",
            loading: false,
            token: "",
            tokenvalido: false,
            message: ""
        }

        this.controller = "api/config/Usuarios";
		this.selectBg = this.selectBg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    async validar(event) {

        this.setState({ message: "" })
        ////console.log("Validando usuario" + this.state.username + " " + this.state.password);
        const response = await fetch(this.controller + '/validar?usuario=' + this.state.username + '&password=' + this.state.password, {
            method: 'GET',
        });
        const data = await response.text();
        //console.log("token recibido: " + JSON.stringify(data));
        this.setState({ token: data });
        localStorage.setItem('token', this.state.token);
        if (localStorage.getItem('token')) {
            //console.log("token existe: " + localStorage.getItem('token'));
            this.validartoken()
            //console.log("token validado: ");
            await this.recuperarUsuario()
            if (this.state.tokenvalido == 'true') {
                var usuario = localStorage.getItem('usuario');
                if (usuario.includes('EMCSJ')) {
                    return this.props.history.push('/dashboard/v3');
                } else {
                    //colapsa la barra de navegación lateral y salta directamente a la página de evaluaciones
                    this.context.toggleSidebarMinify(event);
                    return this.props.history.push('/matilda/evaluaciones');
                };
                
            }
        } else {
            //console.log("token no existe");
            this.setState({ message: "Usuario inexistente" });
        }

    }


    async validartoken()  {
     

        if (localStorage.getItem('token')) {

            //console.log("validando token: ");
            const response = await fetch(this.controller + '/validartoken?accesstoken=' + localStorage.getItem('token'), {
                method: 'GET',
            });
            const data = await response.text();
            //console.log("token validado: " + JSON.stringify(data));
            this.setState({ tokenvalido: data });

            
        } else {
            //console.log("error validando token: ");
            this.setState({ tokenvalido: false });
        }
        //console.log("Token :" + localStorage.getItem('token'));
        //console.log("Tokenactivo:" + this.state.tokenvalido);
        //if (this.state.tokenvalido == 'true') {
        //    return this.props.history.push('/dashboard/v3');
        //}

    }

    async recuperarUsuario() {

        localStorage.setItem('usuario', null);

        const response = await fetch(this.controller + '/getUsuario?usuario=' + this.state.username + '&password=' + this.state.password, {
            method: 'GET',
        });
        const data = await response.text();
        //console.log("usuario:" + JSON.stringify(data));
        localStorage.setItem('usuario', data);

    }

	selectBg(e, active, bg) {
		e.preventDefault();

		this.setState(state => ({
			activeBg: bg,
			bg1: (active === 'bg1') ? true : false,
			bg2: (active === 'bg2') ? true : false,
			bg3: (active === 'bg3') ? true : false,
			bg4: (active === 'bg4') ? true : false,
			bg5: (active === 'bg5') ? true : false,
            bg6: (active === 'bg6') ? true : false,
            username:"",
            password:"",
            loading:false,
            token: "",
            message: ""
		}));
	}

	componentDidMount() {
		this.context.handleSetPageSidebar(false);
        this.context.handleSetPageHeader(false);
        this.context.handleSetPageHeaderLanguageBar(false);
        this.context.handleSetBodyWhiteBg(true);
	}

	componentWillUnmount() {
		this.context.handleSetPageSidebar(true);
		this.context.handleSetPageHeader(true);
        this.context.handleSetPageHeaderLanguageBar(true);
        this.context.handleSetBodyWhiteBg(true);
    }


    componentWillMount() {
        this.validartoken();
     
    }

    handleChangeUsername(event) {
        this.setState({ username: event.target.value })
    }
    handleChangePassword(event) {
        this.setState({ password: event.target.value })
    }

	handleSubmit(event) {
            event.preventDefault();
            this.validar(event);
                   
    }

  
    render() {

		return (
            <div>
                <div className="login login-with-news-feed">
                    <div className="news-feed">
                        {/*<div className="news-image" style={{ backgroundImage: 'url(/assets/color_admin/img/matilda/Matilda_procesando.png)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundSize: '50%' }}></div>*/}
                        <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_procesandoBlanco_sm350x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />

                        <div className="news-caption">
                            <h4 className="caption-title text-secondary"><b>MATILDA</b></h4>
                            <p>
                               ¡Hola! Desde EMC te damos la bienvenida a tu entorno de pruebas, soy Matilda,
                            un robot, y mi superpoder es que soy capaz de leer documentos judiciales e indicarte lo que dicen (muy rápido). ¿Quieres probarlo?
						    </p>
                        </div>
                    </div>
                    <div className="right-content">
                        <div className="login-header">
                            <div className="brand">
                                {/*<img src="/assets/color_admin/img/logo/LogoEMC_b.png" width="180" height="80" />*/}
                                <img src="/assets/color_admin/img/logo/LOGO_EMC_DORADO_BANDERA_mod.png" width="250" height="89" />
                                <div className="m-t-5">
                                    <small></small>
                                </div>
                            </div>
                            <div className="icon">
                                <i className="fa fa-lock"></i>
                            </div>
                            {/*<div className="brand">
							<span className="logo"></span> <b>Color</b> Admin
							<small>responsive bootstrap 4 admin template</small>
						</div>
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>*/}
                        </div>
                        <div className="login-content">
                            <form className="margin-bottom-0" onSubmit={this.handleSubmit}>
                                <div className="form-group m-b-15">
                                    <input type="text" name="username" onChange={this.handleChangeUsername} className="form-control form-control-lg" placeholder="Nombre Usuario" required />
                                </div>
                                <div className="form-group m-b-15">
                                    <input type="password" name="password" onChange={this.handleChangePassword} className="form-control form-control-lg" placeholder="Contraseña" required />
                                </div>
                                <div className="checkbox checkbox-css m-b-30">
                                    <input type="checkbox" id="remember_me_checkbox" value="" />
                                    <label htmlFor="remember_me_checkbox">
                                        Recuérdame
								</label>
                                </div>
                                <div className="login-buttons">
                                    {/*<button type="submit" className="btn btn-success btn-block btn-lg">Entrar</button>*/}
                                    <button type="submit" className="btn btn-primary btn-block btn-lg">Entrar</button>
                                </div>
                                <div className="m-t-20 m-b-40 p-b-40 text-inverse">
                                    No te has registrado aún? Haz click <Link to="/user/register/item/0" className="text-success">aquí</Link> para registrarte.
							</div>
                                <hr />
                                <p className="text-center text-grey-darker">
                                    &copy; EMC Todos los derechos reservados 2020
							</p>
                            </form>
                        </div>
                    </div>
            </div>
          </div>
                
		)
	}
}

export default withRouter(LoginMatilda);


