import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';


class ConfiguracionMotores_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }



    render() {

         return (

             <Form controller='api/config/ConfiguracionMotores' title='Configuración Motores' entity='ConfiguracionMotores' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>

                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Key</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrKey' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Authoring Resource</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrAuthoringResource' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Prediction Resource</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrPredictionResource' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>App Name</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrAppName' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>App Id</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrAppId' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Version Id</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrVersionId' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Slot Name</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrSlotName' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     
                     <div className="col-xl-2">
                         <label>Prefijo Filtro Entidades</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrPrefijoFiltroEntidades' controlType='text' />
                     </div>
                     <div className="col-xl-2">
                         <label>Motor de Encabezado</label>
                     </div>
                     <div className="col-xl-2">
                         <BooleanField field='BlnEncabezado' />
                     </div>
                 </div>
                
            </Form>

        );
    }

}

export default withRouter(ConfiguracionMotores_Item)
