import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';
import JsonEntity from 'comun/components/JsonEntity';
import JsonKey from 'comun/components/JsonKey';
import JsonValue from 'comun/components/JsonValue';



class Documentos_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    

    render() {

        return (

            <Form controller='api/documentos/Documentos' title='Documentos' entity='Documentos' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>

                

                  <div  className="row mb-1">
                     <div className="col-xl-8">
                         <label className="mt-2">Nombre</label>
                     </div>
                     <div className="col-xl-1">
                         
                     </div>
                     <div className="col-xl-2">
                         <label className="mt-2">Motor</label>
                     </div>
                  </div>
                   <div className="row mb-1">
                     <div className="col-xl-8">
                          <Field field='StrNombre' controlType='text' />
                     </div>
                     <div className="col-xl-1">
                         <UploadButton label="Importar" datosAdicionales="" className="btn btn btn-primary" />
                     </div>
                     <div className="col-xl-2">
                         <ComboField field='IntIdMotor' listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' />
                     </div>
                     <div className="col-xl-1">
                         <ActionButtonBackend label="Identificar" action="Identificando" className="btn btn btn-primary" />
                     </div>
                     
                         </div>
                         

                 <div />

                 <div className="row mb-1">
                     <div className="col-xl-12">
                         <label>Párrafos</label>
                     </div>
                 </div>

               
                <EntityArray field='DocumentosSecciones' label='Detalles'>
                     <RowView>
                         <div className="row mb-1">
                             <div className="col-xl-10">
                                 <Field field='StrTexto' type='text' lineas={ 2} />
                            </div>
                            <div className="col-xl-2">
                                <BooleanField field='BlnEncabezado' />
                            </div>
                         </div>
                         <div className="row mb-1">
                             <div className="col-xl-1">

                             </div>
                             <div className="col-xl-11">
                                 <EntityArray field='DocumentosSeccionesSubsecciones' label='Subsecciones'>
                                     <RowView>
                                         <Field field='StrTexto' type='text' lineas={2} />
                                     </RowView>
                                 </EntityArray>
                             </div>
                         </div>
                        <EntityArray field='DocumentosSeccionesIntenciones' label='Declaraciones' enabled={true}>
                             <RowView>
                                 <div className="border border-light rounded-sm mt-2 p-3">
                                     <div className="row mb-1">
                                         <div className="col-xl-2">
                                             <label className="mt-2">Declaración</label>
                                         </div>
                                         <div className="col-xl-4">
                                             <ComboField field='StrClaseIntencion' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/intenciones' navigationPath='' placeHolder='Declare la intención a la que corresponde el párrafo' forceValidation={true}/>
                                         </div>
                                         <div className="col-xl-6">
                                             <JsonEntity entity='JsonIntencion'>
                                                 <div className="row mb-1">
                                                     <div className="col-xl-3">
                                                         <JsonKey />
                                                     </div>
                                                     <div className="col-xl-9">
                                                         <JsonValue />
                                                     </div>
                                                 </div>
                                             </JsonEntity>
                                         </div>
                                     </div>
                                 </div>
                             </RowView>
                             <div className="row mb-1">
                                 <div className="col-xl-2">
                                     <GridActionButton label="Añadir Declaración" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                                 </div>
                             </div>
                         </EntityArray>
                         
                         <div className="row mb-1 ">
                             <div className="col-xl-1">
                             </div>
                             <div className="col-xl-10">
                                <EntityArray field='Identificaciones' label='Identificaciones' enabled={false}>
                                   <RowView>
                                        <div className="border border-light rounded-sm mt-2 p-3">
                                            <div className="row mb-1">
                                               <div className="col-xl-1">
                                                     <label className="mt-2">Identificación</label>
                                                 </div>
                                                 <div className="col-xl-3">
                                                     <DateField field='DtmFecha' />
                                                 </div>
                                                 <div className="col-xl-1">
                                                     <label className="mt-2">Motor</label>
                                                 </div>
                                                 <div className="col-xl-7">
                                                    <ComboField field='IntIdMotor' listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' />
                                                 </div>
                                            </div>
                                            
                                           <EntityArray field='IdentificacionesDetalles' label='Intenciones' enabled={false}>
                                                 <RowView>
                                                     <div className="border border-light rounded-sm mt-2 p-3">
                                                         <div className="row mb-1">
                                                             <div className="col-xl-1">
                                                                 <label className="mt-2">Intención</label>
                                                             </div>
                                                             <div className="col-xl-4">
                                                                 <ComboField field='StrClaseIntencion' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/intenciones' navigationPath='' placeHolder='Intención obtenida del motor de IA' />
                                                             </div>
                                                             <div className="col-xl-1">
                                                                 <label className="mt-2">Valores</label>
                                                             </div>
                                                             <div className="col-xl-6">
                                                                 <JsonEntity entity='JsonIntencion'>
                                                                     <div className="row mb-1">
                                                                         <div className="col-xl-3">
                                                                             <JsonKey />
                                                                         </div>
                                                                         <div className="col-xl-9">
                                                                             <JsonValue />
                                                                         </div>
                                                                     </div>
                                                                 </JsonEntity>
                                                             </div>
                                                         </div>

                                                     </div>
                                                 </RowView>
                                             </EntityArray>
                                            
                                         </div>
                                     </RowView>
                                 </EntityArray>
                             </div>
                             <div className="col-xl-1">
                             </div>
                         </div>

                    </RowView>
                    <div className="row mb-1">
                         <div className="col-xl-2">
                             <GridActionButton label="Añadir Párrafo" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary"/>
                         </div>
                    </div>
                   </EntityArray>

                    
            </Form>

        );
    }

}

export default withRouter(Documentos_Item)
