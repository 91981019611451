import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';


class TiposMotores_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }



    render() {

         return (

             <Form controller='api/config/TiposMotores' title='Tipos de Motores' entity='TiposMotores' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>

   
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Nombre</label>
                     </div>
                     <div className="col-xl-10">
                         <Field field='StrNombre' controlType='text' />
                     </div>
                 </div>
                 <div className="row mb-1">
                     <div className="col-xl-2">
                         <label>Motor</label>
                     </div>
                     <div className="col-xl-10">
                         <ComboField field='IntIdMotor' listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' />
                     </div>
                 </div>
            </Form>

        );
    }

}

export default withRouter(TiposMotores_Item)

