import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import Entity from 'comun/components/Entity';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';
import JsonEntity from 'comun/components/JsonEntity';
import JsonKey from 'comun/components/JsonKey';
import JsonValue from 'comun/components/JsonValue';



class Iteraciones_Item extends Component {



    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    
    

    RedireccionPeticionTest = () => {
        let idPeticion = document.getElementById("IdPeticion").firstChild.value;
        let NewPathName = "/matilda/peticiones/item/" + idPeticion;
        let url = "https://" + window.location.host + NewPathName;
        window.open(url, "_blank");
        
    }

    RedireccionPeticionRef = () => {
        let idPeticionRef = document.getElementById("idPeticionRef").firstChild.value;
        let NewPathName = "/matilda/peticiones/item/" + idPeticionRef;
        let url = "https://" + window.location.host + NewPathName;
        window.open(url, "_blank");

    }


    render() {

        return (

            <Form controller='api/config/calidadDocumentosTest' title='Iteraciones' entity='CalidadDocumentosTest' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>
                
                <div className="col-xl-12 border border-light rounded p-5">
                    <Entity entity='IntIdCalidadDocumentosNavigation' label='Calidad Documentos' >
                        <h3>Documento</h3>
                        <div className="row mb-1">
                            <div className="col-xl-4">
                                <label className="mt-2">Ruta</label>
                            </div>
                            <div className="col-xl-4">
                                <label className="mt-2">Cabecera</label>
                            </div>
                            <div className="col-xl-4">
                                <label className="mt-2">Resolución</label>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-xl-4">
                                <Field field='StrRuta' controlType='text' />
                            </div>
                            <div className="col-xl-4">
                                <Field field='StrDocumentoCabecera' />
                            </div>
                            <div className="col-xl-4">
                                <Field field='StrDocumentoResolucion' />
                            </div>
                        </div>
                        
                    </Entity>
                </div>
                <div className="d-flex ">
                    <div className="col-xl-6 border border-light rounded p-5">
                        <Entity entity='IntIdCalidadDocumentosNavigation' label='Calidad Documentos' >
                            <h3>Referencia</h3>
                            <div className="row mb-1">
                                <div className="col-xl-5">
                                    <label className="mt-2">ID</label>
                                </div>
                                
                                <div className="col-xl-5">
                                    <label className="mt-2">Id Petición Referencia</label>
                                </div>
                                <div className="col-xl-2">
                                    <label className="mt-2">Ignorar</label>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-xl-5">
                                    <Field field='IntId' controlType='text' enabled={false} />
                                </div>
                                <div className="col-xl-5" id="idPeticionRef">
                                    <Field field='IntIdPeticionReferencia' controlType='text' enabled={false} />
                                </div>
                                <div className="col-xl-2">
                                    <BooleanField field='BlnIgnorar' />
                                </div>
                            </div>
                            <div className="row invisible">
                                <div className="col-xl-6">
                                    <label className="mt-2">ID</label>
                                </div>

                                <div className="col-xl-6">
                                    <label className="mt-2">Id Petición Referencia</label>
                                </div>
                            </div>
                            <div className="row invisible">
                                <div className="col-xl-6">
                                    <Field field='IntId' controlType='text' enabled={false} />
                                </div>
                                <div className="col-xl-6">
                                    <Field field='IntIdPeticionReferencia' controlType='text' enabled={false} />
                                </div>

                            </div>
                            <button className="btn btn btn-primary" onClick={(e) => this.RedireccionPeticionRef()}> Ir a la petición de referencia</button>
                            <div className="border border-light rounded p-5 mt-3">
                                <Entity entity='IntIdPeticionReferenciaNavigation' label='Peticiones'>
                                <h3>Petición Referencia</h3>
                                <div className="row mb-2">
                                    <div className="col-xl-3">
                                        <label className="mt-2">Regla Encontrada</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Score</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Referencia Externa</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Estado</label>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-xl-3">
                                        <Field field='StrReglaEncontrada' controlType='text' />
                                    </div>
                                    <div className="col-xl-3">
                                        <NumberField field='DblScoreRegla' />
                                    </div>
                                    <div className="col-xl-3">
                                        <Field field='StrExternalReference' />
                                    </div>
                                    <div className="col-xl-3">
                                        <Field field='IntEstado' />
                                    </div>
                                </div>

                                <EntityArray field='PeticionesVoces' label='Peticiones Voces'>
                                    <RowView>
                                        <div className="row mb-1">
                                            <div className="col-xl-5">
                                                <label className="mt-2">Clase Voz</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <label className="mt-2">Json</label>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-xl-5">
                                                <Field field='StrClaseVoz' controlType='text' />
                                            </div>
                                            <div className="col-xl-7">
                                                <JsonEntity entity='JsonVoz'>
                                                    <div className="row mb-1">
                                                        <div className="col-xl-6">
                                                            <JsonKey />
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <JsonValue />
                                                        </div>
                                                    </div>
                                                </JsonEntity>
                                            </div>


                                        </div>
                                    </RowView>
                                </EntityArray >
                                </Entity>
                            </div>
                            <div className="row mb-1">
                                <div className="col-xl-12">
                                    <label className="mt-2">Comentarios</label>
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-xl-12">
                                    <Field field='StrComentarios' controlType='text' lineas={2} />
                                </div>
                            </div>
                        </Entity>

                    </div> 
                    <div className="col-xl-6 border border-light rounded p-5 ">
                        <h3>Nuevo Test</h3>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <label className="mt-2">ID</label>
                            </div>
                            <div className="col-xl-3">
                                <label className="mt-2">Fecha</label>
                            </div>
                            <div className="col-xl-3">
                                <label className="mt-2">Identificador</label>
                            </div>
                            {/*<div className="col-xl-2">
                                <label className="mt-2">Ignorar</label>
                            </div>*/}
                            <div className="col-xl-2">
                                <label className="mt-2">Revisado</label>
                            </div>
                            <div className="col-xl-2">
                                <label className="mt-2">ID Peticion</label>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <Field field='IntId' controlType='text' enabled={false} />
                            </div>
                            <div className="col-xl-3">
                                <Field field='DtmFecha' controlType='datetime-local' />
                            </div>
                            <div className="col-xl-3">
                                <Field field='StrIdentificadorTest' />
                            </div>
                            {/*<div className="col-xl-2">
                                <BooleanField field='BlnIgnorar' />
                            </div>*/}
                            <div className="col-xl-2">
                                <BooleanField field='BlnRevisado' />
                            </div>
                            <div className="col-xl-2" id="IdPeticion">
                                <Field field='IntIdPeticion' controlType='text' enabled={false} />
                            </div>

                        </div>

                        <div className="row mb-1">
                            <div className="col-xl-1">
                                <label className="mt-2">Estado</label>
                            </div>
                            <div className="col-xl-1">
                                <label className="mt-2">Ok</label>
                            </div>
                            <div className="col-xl-1">
                                <label className="mt-2">Regla</label>
                            </div>
                            <div className="col-xl-2">
                                <label className="mt-2">Referencia Externa</label>
                            </div>
                            <div className="col-xl-1">
                                <label className="mt-2">Voces</label>
                            </div>
                            <div className="col-xl-2">
                                <label className="mt-2">Json Voces</label>
                            </div>
                            <div className="col-xl-2">
                                <label className="mt-2">Corte Pdf</label>
                            </div>
                            <div className="col-xl-1">
                                <label className="mt-2">Alerta Score</label>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-xl-1">
                                <BooleanField field='BlnEstado' enabled={false} />
                            </div>
                            <div className="col-xl-1">
                                <BooleanField field='BlnResultadoOk' enabled={false} />
                            </div>
                            <div className="col-xl-1">
                                <BooleanField field='BlnRegla' enabled={false} />
                            </div>
                            <div className="col-xl-2">
                                <BooleanField field='BlnReferenciaExterna' enabled={false} />
                            </div>
                            <div className="col-xl-1">
                                <BooleanField field='BlnVoces' enabled={false} />
                            </div>
                            <div className="col-xl-2">
                                <BooleanField field='BlnJsonVoces' enabled={false} />
                            </div>
                            <div className="col-xl-2">
                                <BooleanField field='BlnCortePdf' controlType='text' enabled={false} />
                            </div>
                            <div className="col-xl-1">
                                <BooleanField field='BlnAlertaScore' />
                            </div>
                        </div>

                        <div className="row mb-1">
                            <div className="col-xl-6">
                                <button className="btn btn btn-primary" onClick={(e) => this.RedireccionPeticionTest()}> Ir a la petición de Test</button>
                            </div>
                            <div className="col-xl-6">
                                <ActionButtonBackend label="Establecer Como Referencia" action="seleccionar" className="btn btn btn-danger" />
                            </div>
                        </div>
                        
                        <div className="border border-light rounded p-5">
                            <Entity entity='IntIdPeticionNavigation' label='Peticiones'>
                                <h3>Petición</h3>
                                <div className="row mb-1">
                                    <div className="col-xl-3">
                                        <label className="mt-2">Regla Encontrada</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Score</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Referencia Externa</label>
                                    </div>
                                    <div className="col-xl-3">
                                        <label className="mt-2">Estado</label>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-xl-3">
                                        <Field field='StrReglaEncontrada' controlType='text' />
                                    </div>
                                    <div className="col-xl-3">
                                        <NumberField field='DblScoreRegla' />
                                    </div>
                                    <div className="col-xl-3">
                                        <Field field='StrExternalReference' />
                                    </div>
                                    <div className="col-xl-3">
                                        <Field field='IntEstado' />
                                    </div>
                                </div>
                                <EntityArray field='PeticionesVoces' label='Peticiones Voces'>
                                    <RowView>
                                        <div className="row mb-1">
                                            <div className="col-xl-5">
                                                <label className="mt-2">Clase Voz</label>
                                            </div>
                                            <div className="col-xl-7">
                                                <label className="mt-2">Json</label>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <div className="col-xl-5">
                                                <Field field='StrClaseVoz' controlType='text' />
                                            </div>
                                            <div className="col-xl-7">
                                                <JsonEntity entity='JsonVoz'>
                                                    <div className="row mb-1">
                                                        <div className="col-xl-6">
                                                            <JsonKey />
                                                        </div>
                                                        <div className="col-xl-6">
                                                            <JsonValue />
                                                        </div>
                                                    </div>
                                                </JsonEntity>
                                            </div>
                                        </div>
                                    </RowView>
                                </EntityArray >
                            </Entity >
                        </div>
                        <div className="row mb-1">
                            <div className="col-xl-12">
                                <label className="mt-2">Comentarios</label>
                            </div>
                        </div>
                        <div className="row mb-1">
                            <div className="col-xl-12">
                                <Field field='StrComentarios' controlType='text' lineas={ 2 } />
                            </div>
                        </div>

                </div> 
                </div>
            </Form>

        );
    }

}

export default withRouter(Iteraciones_Item)
