import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import Evaluaciones_Item from './Evaluaciones_Item';
import { Panel, PanelHeader, PanelBody, PanelStat } from 'color_admin/components/panel/panel.jsx';
import { ReactTableDefaults } from 'react-table';
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Button } from 'primereact/button';
import ReactPlayer from "react-player";


/*sobrescribir el componente de paginación*/
import Pagination from 'comun/components/Pagination';
Object.assign(ReactTableDefaults, {
    PaginationComponent: Pagination
});


class Evaluaciones extends Component {

    constructor() {
        super();

        this.linkItem = "/matilda/evaluaciones/item"
        this.controller = "api/peticiones/peticiones";
        this.dt = undefined;


        this.state = {
            textoBusqueda: '',
            resultadosBusqueda: [],
            resultadoSeleccionado: null,
            sorted: [],
            page: 0,
            pageSize: 10,
            expanded: {},
            resized: [],
            filtered: [],
            totalSize: 0
        };


    }

    actualizarTextoBusqueda(strTexto) {
        this.setState({ textoBusqueda: strTexto });
    };


    seleccionarResultado(seleccion) {
        this.setState({ resultadoSeleccionado: seleccion });
        this.mostrarItem(seleccion["IntId"]);
    };

    mostrarItem(itemId, toggleCollapse) {

        this.props.history.push(this.linkItem + "/" + itemId);
        if (itemId === 0) {
            //hace que se refresque para que siempre reaccione al nuevo
            //this.props.history.go();
            toggleCollapse();
        }


    };



    async getResults() {
        this.loading = true;
        const response = await fetch(this.controller + '/filter?filter=' + this.state.textoBusqueda, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        });

        const data = await response.json();
        this.setState({ resultadosBusqueda: data });
        this.loading = false;

    }

    procesarBusqueda(key) {
        if (key === "Enter") {
            this.getResults();
        }
    }

    exportCSV() {
        if (this.dt !== undefined) {
            this.dt.exportCSV();
        }
    }

    setDt(dt) {
        if (dt != null) {
            this.dt = dt;
        }
    }


    render() {


        const header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" label="Exportar" onClick={event => this.exportCSV()}></Button></div>;

        return (

            <div className="contenedor" >

                <div>
                    <Panel>
                        <PanelHeader>Instrucciones</PanelHeader>
                        <PanelBody>
                            <PanelStat.Consumer>
                                {({ toggleCollapse }) => (

                                    <div className="row mb-1">
                                        <div className="col-xl-3 p-r-0">
                                            <div className='player-wrapper'>
                                                <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_haloBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                                            </div>
                                            {/*
                                            <img src="/assets/color_admin/img/matilda/Matilda_Holograma_provisJaime.png" width="300" height="387" className="float-right" />
                                            <img src="/assets/color_admin/img/matilda/Matilda_halodeluz-sm.png" width="300" height="300" />
                                            */}
                                        </div>
                                        <div className="col-xl-9 p-md-4 rounded holograma">
                                            <div class="h6 font-weight-normal">
                                                <p>Gracias por entrar en este entorno de Pruebas. Antes de empezar recuerda tener una notificación procesal a mano con su cabecera de LexNET. Mi entrenamiento está basado en juicios dinerarios y todavía no puedo analizar sentencias.</p>
                                                <p>Lo que hago es que analizo notificaciones procesales y obtengo de ellas sus conclusiones más relevantes, se me da muy bien y soy muy rápida, puedo llegar a una por segundo y a procesar con éxito más de un 80% de las que interpreto.</p>
                                                <div className="h5">INSTRUCCIONES:</div>
                                                <ul>
                                                    <li className="p-b-1 h5">Pulse el botón "Nueva Evaluación"</li>
                                                    <li className="p-b-1 h5">Importe el documento de cabecera de Lexnet</li>
                                                    <li className="p-b-1 h5">Importe el documento de resolución</li>
                                                    <li className="p-b-1 h5">El resultado se mostrará automáticamente</li>
                                                </ul>
                                                <p>Todavía me queda mucho por aprender y puede ser que no sea capaz de entender tu notificación, en ese caso probablemente sea porque:</p>
                                                <ul>
                                                    <li className="p-b-5">No has subido la Cabecera de LexNET o se ha cambiado el nombre orginal del archivo</li>
                                                    <li className="p-b-5">El documento es ilegible</li>
                                                    <li className="p-b-5">El procedimiento que has subido no es una reclamación de cantidad o juicio dinerario o es una sentencia (todavía no las domino, estoy en ello)</li>
                                                </ul>
                                                <p>¡Por último! Esto es un entorno de pruebas, normalmente no me muestro así, mi segundo superpoder es el del camuflaje, me integro con cualquier otro software para contribuir a mejorar la eficiencia de tu empresa, recojo las notificaciones de donde me digas y presento mis resultados en el formato que mejor os venga 😊</p>
                                                <p class="font-weight-bold">¡Ya podemos empezar!</p>

                                                <div className="row mb-1 ml-4">
                                                    {/*<button type="button" className="btn btn-blue" onClick={event => this.mostrarItem(0, toggleCollapse)}>Nueva evaluación</button>*/}
                                                    <button type="button" className="btn btn-primary" onClick={event => this.mostrarItem(0, toggleCollapse)}>Nueva evaluación</button>
                                                </div>
                                            </div>




                                            {/*
                                            <div className="m-4 ml-4">
                                                <div className="row mb-1 ml-4">
                                                    <h5> </h5>
                                                </div>


                                                <div className="row mb-1 ml-4">
                                                    <h6>Gracias por entrar en este entorno de Pruebas. Antes de empezar recuerda tener una notificación procesal a mano con su cabecera de LexNET. Mi entrenamiento está basado en juicios dinerarios y todavía no puedo analizar sentencias.</h6>
                                                    <h6>Lo que hago es que analizo notificaciones procesales y obtengo de ellas sus conclusiones más relevantes, se me da muy bien y soy muy rápida, puedo llegar a una por segundo y a procesar con éxito más de un 80% de las que interpreto.</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h5>INSTRUCCIONES:</h5>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h5>- Pulse el botón "Nueva Evaluación"</h5>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h5>- Importe el documento de cabecera de Lexnet</h5>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h5>- Importe el documento de resolución</h5>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h5>- El resultado se mostrará automáticamente</h5>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>Todavía me queda mucho por aprender y puede ser que no sea capaz de entender tu notificación, en ese caso probablemente sea porque:</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>- No has subido la Cabecera de LexNET</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>- El documento es ilegible</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>- El procedimiento que has subido no es una reclamación de cantidad o juicio dinerario o es una sentencia (todavía no las domino, estoy en ello)</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>"¡Por último! Esto es un entorno de pruebas, normalmente no me muestro así, mi segundo superpoder es el del camuflaje, me integro con cualquier otro software para contribuir a mejorar la eficiencia de tu empresa, recojo las notificaciones de donde me digas y presento mis resultados en el formato que mejor os venga 😊"</h6>
                                                </div>
                                                <div className="row mb-1 ml-4">
                                                    <h6>¡Ya podemos empezar!</h6>
                                                </div>


                                                <div className="mt-4"></div>
                                                <div className="row mb-1 ml-4">
                                                    <button type="button" className="btn btn-primary" onClick={event => this.mostrarItem(0, toggleCollapse)}>Nueva evaluación</button>
                                                </div>
                                            </div>
                                            */}




                                        </div>
                                    </div>
                                )}
                            </PanelStat.Consumer>



                        </PanelBody>
                    </Panel>

                </div>
                <div className="rellenar" >

                    <Panel className="contenedor">
                        <PanelHeader>Evaluación</PanelHeader>
                        <PanelBody className="rellenar" >
                            <Route path='/matilda/evaluaciones/item/:itemId' component={Evaluaciones_Item} />
                        </PanelBody>
                    </Panel>

                </div>
            </div>



        );
    }

}


export default withRouter(Evaluaciones)
