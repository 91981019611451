
import React from 'react';
import { Route, withRouter, useHistory, Switch } from 'react-router-dom';
import routes from 'matilda/app/page-route.jsx';
import { PageSettings } from 'color_admin/config/page-settings.js';
import ComprobarIdentificacion from '../../../matilda/pages/seguridad/ComprobarIdentificacion.js';


const RenderRoute=(route) => {
    const history = useHistory();
    // Si tiene el parametro needsAuth a true se comrpueba la idnetificación
    if (route.needsAuth && !ComprobarIdentificacion()) {
        console.log(route.needsAuth);
        console.log(route.path)
        // Si no está validado se manda al login
       history.push('/matilda/pages/seguridad/Login-Matilda');
    }
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => <route.component {...props} />}
        />
    );
};



function setTitle(path, routeArray) {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = 'Matilda | ' + routeArray[i].title;
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Matilda | EMC';
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname, routes);
	}
	componentWillMount() {
    this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname, routes);
    });
  }

   
	render() {
		return (
			<PageSettings.Consumer>
                {({ pageContentFullWidth, pageContentClass, pageContentInverseMode, pageContentFullHeight}) => (
                    <div className={'content ' + (pageContentFullHeight ? 'content-full-height ' : '') + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass}>
                        {/*routes.map Debe estar dentro del Switch porque debe cargar una a una y no todas de golpe, sino en la compraboación de rutas entra en bucle*/}
                        <Switch>
                        {routes.map((route, index) => <RenderRoute {...route} key={index} />)};
                       	 </Switch>					
					</div>
				)
			}
			</PageSettings.Consumer>
		)
	}
}

export default withRouter(Content);
