import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import Entity from 'comun/components/Entity';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';
import JsonEntity from 'comun/components/JsonEntity';
import JsonKey from 'comun/components/JsonKey';
import JsonValue from 'comun/components/JsonValue';
import ConditionalRender from 'comun/components/ConditionalRender';
import ReactPlayer from "react-player";


class Evaluaciones_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    mostrarItem(itemId) {

        this.props.history.push("/matilda/evaluaciones/item/" + itemId);
    }

    render() {

         return (

             <Form controller='api/peticiones/Peticiones' title='Evaluaciones' entity='Peticiones' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true} showButtons={false}>

                
                         <ConditionalRender field='IntEstado' equalValue={0}>
                             <div className="row mb-1">
                                 {/*
                                 <div className="col-xl-3">
                                     <img src="/assets/color_admin/img/matilda/Matilda_procesando.png"  width="350" height="496" />
                                 </div>
                                 */}
                                 <div className='player-wrapper'>
                                     <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_señalandoBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                                 </div>

                                 <div className="col-xl-9">
                                     <Entity entity='IntIdDocumentoCabeceraNavigation' label='Cabecera'>
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                 <label className="mt-2">Cabecera</label>
                                             </div>
                                             <div className="col-xl-1">
                                             </div>
                                         </div>
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                 <Field field='StrNombre' controlType='text' enabled={false} placeHolder='Seleccione el documento de notificación de Lexnet'/>
                                             </div>
                                             <div className="col-xl-1">
                                         <UploadButton label="Importar" datosAdicionales="cabecera+save" className="btn btn btn-primary" mensaje="Importando Cabecera" imagen="/assets/color_admin/img/matilda/Matilda_procesando.png" />
                                             </div>
                                         </div>
                                     </Entity>
                                 </div>
                                 </div>
                 </ConditionalRender>


                 <ConditionalRender field='IntEstado' contains={1000} notContains={99}>
                     <div className="row mb-1">
                         {/*
                                 <div className="col-xl-3">
                                     <img src="/assets/color_admin/img/matilda/Matilda_procesando.png" width="350" height="496" />
                                 </div>
                                 */}
                         <div className='player-wrapper'>
                             <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_señalandoBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                         </div>
                         <div className="col-xl-9">
                             <Entity entity='IntIdDocumentoCabeceraNavigation' label='Cabecera'>
                                 <div className="row mb-1">
                                     <div className="col-xl-8">
                                         <label className="mt-2">Cabecera</label>
                                     </div>
                                 </div>
                                 <div className="row mb-1">
                                     <div className="col-xl-8">
                                         <Field field='StrNombre' controlType='text' enabled={false} />
                                     </div>
                                 </div>
                             </Entity>
                             <Entity entity='IntIdDocumentoResolucionNavigation' label='Resolución' >

                                 <div className="row mb-1">
                                     <div className="col-xl-8">
                                         <label className="mt-2">Resolución</label>
                                     </div>
                                     <div className="col-xl-2">

                                     </div>

                                 </div>
                                 <div className="row mb-1">
                                     <div className="col-xl-8">
                                         <Field field='StrNombre' controlType='text' enabled={false} placeHolder='Seleccione el documento de resolución' />
                                     </div>
                                     <div className="col-xl-2">
                                         <UploadButton label="Importar" datosAdicionales="resolucion+procesar+save" className="btn btn btn-primary" mensaje="Leyendo resolución" imagen="/assets/color_admin/img/matilda/Matilda_procesando.png" />
                                     </div>
                                 </div>
                             </Entity>
                         </div>
                     </div>
                 </ConditionalRender>


                 <ConditionalRender field='IntEstado' contains={1200} notContains={99}>
                             <div className="row mb-1">
                                 {/*
                                 <div className="col-xl-3">
                                     <img src="/assets/color_admin/img/matilda/Matilda_procesando.png" width="350" height="496" />
                                 </div>
                                 */}
                                 <div className='player-wrapper'>
                                     <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_señalandoBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                                 </div>
                                 <div className="col-xl-9">
                                     <Entity entity='IntIdDocumentoCabeceraNavigation' label='Cabecera'>
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                 <label className="mt-2">Cabecera</label>
                                             </div>
                                         </div>
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                 <Field field='StrNombre' controlType='text' enabled={false} />
                                             </div>
                                         </div>
                                     </Entity>
                                     <Entity entity='IntIdDocumentoResolucionNavigation' label='Resolución' >
                                         
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                 <label className="mt-2">Resolución</label>
                                             </div>
                                             <div className="col-xl-2">

                                             </div>
                                            
                                         </div>
                                         <div className="row mb-1">
                                             <div className="col-xl-8">
                                                <Field field='StrNombre' controlType='text' enabled={false} placeHolder='Seleccione el documento de resolución'/>
                                             </div>
                                             <div className="col-xl-2">
                                         <UploadButton label="Importar" datosAdicionales="resolucion+procesar+save" className="btn btn btn-primary" mensaje="Leyendo resolución" imagen="/assets/color_admin/img/matilda/Matilda_procesando.png"/>
                                             </div>
                                         </div>
                                     </Entity>
                                 </div>
                             </div>
                         </ConditionalRender>




                 <ConditionalRender field='IntEstado' contains={123} notContains={99}>
                             <div className="row mb-1">
                         <div className="col-xl-4">
                                <div className='player-wrapper'>
                                    <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_okBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                                </div>
                                {/*
                                <div className="row mb-1">
                                     <img src="/assets/color_admin/img/matilda/Matilda_procesando.png" width="350" height="496" />
                                </div>
                                */}
                                 <div className="row mb-1 ml-4">
                                     <button type="button" className="btn btn-primary" onClick={event => this.mostrarItem(0)}>Nueva evaluación</button>
                                 </div>
                                 </div>
                                 <div className="col-xl-8">
                                     <div className="row mb-1">
                                         <div className="col-xl-12">
                                             <h5>Resultados</h5>
                                         </div>
                                     </div>
                                     <div className="border border-light rounded-sm mt-2 p-3">
                                         <div className="border border-light rounded-lg mt-2 mb-3 p-3 font-weight-bold mx-1 box-resumen" >
                                             <div className="row mb-1 mx-1">
                                                 <div className="col-xl-10 font-weight-bold">
                                                     <label className="mt-2">Resumen</label>
                                                 </div>
                                                 <div className="col-xl-2 font-weight-bold">
                                                     <label className="mt-2">% Fiabilidad</label>
                                                 </div>
                                             </div>
                                             <div className="row mb-3 mx-1">
                                                 <div className="col-xl-10 ">
                                                     <Field field='StrResumenRegla' type='text' lineas={3} className='display-1' />
                                                 </div>
                                                 <div className="col-xl-2 ">
                                                     <Field field='DblScoreRegla' type='text' className='display-1'  />
                                                 </div>
                                             </div>
                                         </div>
                                         <div className="row mb-1">
                                             <div className="col-xl-12">
                                                 <label className="font-weight-bold">Datos de resultados</label>
                                             </div>
                                         </div>

                                         <EntityArray field='PeticionesVoces' label='Voces'>
                                             <RowView>

                                                 <div className="row mb-1">

                                                     <div className="col-xl-5">
                                                         <ComboField field='StrClaseVoz' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/voces' navigationPath='' placeHolder='Declare la voz que desea añadir a la regla' forceValidation={true} enabled={false} />
                                                     </div>
                                                     
                                                     <div className="col-xl-7">
                                                        <JsonEntity entity='JsonVoz' enabled={false}>
                                                             <div className="row mb-1">
                                                                 <div className="col-xl-3">
                                                                     <JsonKey />
                                                                 </div>
                                                                 <div className="col-xl-9">
                                                                     <JsonValue />
                                                                 </div>
                                                             </div>
                                                         </JsonEntity>
                                                     </div>
                                                 </div>
                                             </RowView>

                                         </EntityArray>

                                     </div>
                         
                                 </div>
                                 </div>
                                 
                 </ConditionalRender>


                 <ConditionalRender field='IntEstado' contains={99}>
                             <div className="row mb-1">
                                 <div className='player-wrapper'>
                                     <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_errorBlanco_sm300x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                                 </div>
                                 <div className="col-xl-9">
                                     <div className="alert alert-danger" >
                                        <p className="font-weight-bold">Lo siento, no he podido procesar tu petición.</p>
                                        <p>
                                            Recuerda que por el momento sólo puedo procesar documentos en formato PDF con texto legible (OCR),
                                            que necesito leer la cabecera de LEXNET (sin cambiar el nombre del archivo) además del documento que quieras procesar, y que me estoy entrenando solo en procedimientos relacionados con reclamaciones de cantidad o dinerarios.
                                        </p>
                                        <p className="font-weight-bold">¿Quieres volver a intentar?</p>
                                     </div>
                                     <button type="button" className="btn btn-primary btn-lg" onClick={event => this.mostrarItem(0)}>Nueva evaluación</button>
                                 </div>
                            </div>
                         </ConditionalRender>
          
                 
                 
            </Form>

        );
    }

}

export default withRouter(Evaluaciones_Item)
