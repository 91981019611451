import React from 'react';


const Menu = [
    {
        path: '/matilda', icon: 'far fa-bookmark', title: 'Matilda', 
        children: [
            { path: '/matilda/documentos', title: 'Documentos', rol:'EMCSJ'},
            { path: '/matilda/peticiones', title: 'Peticiones', rol: 'EMCSJ' },
            { path: '/matilda/evaluaciones', title: 'Evaluaciones', rol: 'CLIENTE' },
        ]
    },
    {
        path: '/configuracion', icon: 'far fa-bookmark', title: 'Configuración', rol:'EMCSJ',
        children: [
            { path: '/matilda/configuracion/motores', title: 'Motores' },
            { path: '/matilda/tiposprocedimientos', title: 'Tipos de Procedimientos' },
            { path: '/matilda/tiposmotores', title: 'Tipos de Motores' },
            { path: '/matilda/diccionario', title: 'Diccionario' },
            { path: '/matilda/reglas', title: 'Reglas' },
            { path: '/matilda/parametros', title: 'Parámetros' },
            { path: '/matilda/hitos', title: 'Hitos' },
        ]
    },
    {
        path: '/matilda', icon: 'far fa-bookmark', title: 'Test de Calidad', 
        children: [
            { path: '/matilda/iteraciones', title: 'Iteraciones' },
        ]
    },
  
]

export default Menu;

