import React, { Component } from 'react';
import Selector, { SelectorContext} from 'comun/components/Selector.js';
import { withRouter } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


class SelectorConfiguracionMotores extends Component {
   
    render() {
        
        const { field } = this.props;

        return (
            <Selector field={field} listKeyField='IntId' listDisplayField='StrAppName' listController='api/config/configuracionmotores' navigationPath='/matilda/configuracion/motores/item' placeHolder='Seleccione un elemento' >
                <SelectorContext.Consumer>
                    {({ resultadosBusqueda, resultadoSeleccionado, seleccionarResultado }) => (
                            <DataTable value={resultadosBusqueda} selection={resultadoSeleccionado} onSelectionChange={event => seleccionarResultado(event.value)} className="p-datatable-striped" selectionMode="single" resizableColumns columnResizeMode="fit"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}" rows={10} rowsPerPageOptions={[5, 10, 20, 50]}
                            scrollable scrollHeight="300px" sortMode="multiple" csvSeparator=";" >
                            <Column field="IntId" header="ID" style={{ width: '20%' }} sortable filter filterPlaceholder="Buscar ID"></Column>
                            <Column field="StrAppName" header="App Name" sortable filter filterPlaceholder="Filtrar por App Name" filterMatchMode="contains"></Column>
                        </DataTable>
                    )}
                </SelectorContext.Consumer>
                
            </Selector>
        );
    }
}

export default withRouter(SelectorConfiguracionMotores);

