import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import EntityArray from 'comun/components/EntityArray';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import GridActionButton from 'comun/components/GridActionButton';
import RowView from 'comun/components/RowView';
import CardView from 'comun/components/CardView';
import SelectorConfiguracionMotores from 'matilda/pages/config/SelectorConfiguracionMotores';
import JsonEntity from 'comun/components/JsonEntity';
import JsonKey from 'comun/components/JsonKey';
import JsonValue from 'comun/components/JsonValue';



class Reglas_Item extends Component {

    state = {
        modal: false
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    

    render() {

        return (

            <Form controller='api/analyticsrules/analyticsrules' title='Reglas' entity='AnalyticsRules' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true}>
                

                  <div  className="row mb-1">
                     <div className="col-xl-5">
                         <label className="mt-2">Nombre</label>
                     </div>
                    <div className="col-xl-2">
                        <label className="mt-2">Referencia Externa</label>
                    </div>
                    <div className="col-xl-1">
                        <label className="mt-2">Orden</label>
                    </div>
                    <div className="col-xl-1">
                        <label className="mt-2">Finalizar</label>
                    </div>
                    <div className="col-xl-1">
                        <label className="mt-2">Regla Común</label>
                    </div>
                    <div className="col-xl-2">
                        <label className="mt-2">Hito</label>
                    </div>
                  </div>
                  <div className="row mb-1">
                     <div className="col-xl-5">
                          <Field field='StrDescription' controlType='text' />
                     </div>
                    <div className="col-xl-2">
                        <Field field='StrExternalReference' controlType='text' />
                    </div>
                    <div className="col-xl-1">
                        <NumberField field='IntOrder' />
                    </div>
                    <div className="col-xl-1 pt-2">
                        <BooleanField field='BlnEndProcess' />
                    </div>
                    <div className="col-xl-1 pt-2">
                        <BooleanField field='BlnReglaComun' />
                    </div>
                    <div className="col-xl-2">
                        <ComboField field='IntIdHito' listKeyField='IntId' listDisplayField='StrNombre' listController='api/hitos' navigationPath='/matilda/configuracion/hitos/item' placeHolder='Seleccione un elemento' />
                    </div>
                    
                 </div>

                <div />

                {/*<div className="row mb-1">
                    <div className="col-xl-12">
                        <label>TiposProcedimientos</label>
                    </div>
                </div>

                <div className="border border-light rounded-sm mt-2 p-3">
                    <EntityArray field='AnalyticsRulesTiposProcedimientos' label='Tipos procedimientos'>
                        <RowView>
                        
                                <div className="row mb-1">
                                    <div className="col-xl-5">
                                        <ComboField field='IntIdTipoProcedimiento' listKeyField='IntId' listDisplayField='StrNombre' listController='api/config/tiposprocedimientos' navigationPath='/matilda/configuracion/tiposprocedimientos/item' placeHolder='Seleccione un elemento' />
                                    </div>
                                </div>
                       
                        </RowView>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <GridActionButton label="Añadir Tipo Procedimiento" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                            </div>
                        </div>

                     </EntityArray>
                </div>*/}

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Tiposde Motores</label>
                    </div>
                </div>

                <div className="border border-light rounded-sm mt-2 p-3">
                    <EntityArray field='AnalyticsRulesTiposMotores' label='Tipos motores'>
                        <RowView>

                            <div className="row mb-1">
                                <div className="col-xl-5">
                                    <ComboField field='IntIdTipoMotor' listKeyField='IntId' listDisplayField='StrNombre' listController='api/config/tiposmotores' navigationPath='/matilda/configuracion/tiposmotores/item' placeHolder='Seleccione un elemento' />
                                </div>
                            </div>

                        </RowView>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <GridActionButton label="Añadir Tipo Motor" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                            </div>
                        </div>

                    </EntityArray>
                </div>

                <div />

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Exclusiones de reglas comunes</label>
                    </div>
                </div>

                <div className="border border-light rounded-sm mt-2 p-3">
                    <EntityArray field='AnalyticsRulesExclusionsIntIdRuleNavigation' label='Exclusiones'>
                        <RowView>

                            <div className="row mb-1">
                                <div className="col-xl-5">
                                    <ComboField field='IntIdRuleExcluded' listKeyField='IntId' listDisplayField='StrDescription' listController='api/analyticsrules/analyticsrules' listMethod='reglascomunes' navigationPath='' placeHolder='Declare la regla que desee excluir' forceValidation={true}/>
                                </div>
                            </div>

                        </RowView>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <GridActionButton label="Añadir Exclusión" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                            </div>
                        </div>

                    </EntityArray>
                </div>

                
                 <div />

                 <div className="row mb-1">
                     <div className="col-xl-12">
                         <label>Voces</label>
                     </div>
                 </div>

               
                <EntityArray field='AnalyticsRulesVoices' label='Voces'>
                    <RowView>
                        <div className="border border-light rounded-sm mt-2 p-3">
                            <div className="row mb-1">
                                <div className="col-xl-2">
                                    <label className="mt-2">Voz</label>
                                </div>
                                <div className="col-xl-3">
                                    <ComboField field='StrClaseVoz' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/voces' navigationPath='' placeHolder='Declare la voz que desea añadir a la regla' forceValidation={true} />
                                </div>
                                <div className="col-xl-2">
                                    <label className="mt-2">Referencia externa</label>
                                </div>
                                <div className="col-xl-2">
                                    <Field field='StrExternalReference' controlType='text' />
                                </div>
                                <div className="col-xl-6">
                                    <JsonEntity entity='JsonVoz'>
                                        <div className="row mb-1">
                                            <div className="col-xl-3">
                                                <JsonKey />
                                            </div>
                                            <div className="col-xl-7">
                                                <JsonValue />
                                            </div>
                                        </div>
                                    </JsonEntity>
                                </div>
                            </div>
                        </div>
                    </RowView>
                    <div className="row mb-1">
                        <div className="col-xl-2">
                            <GridActionButton label="Añadir Voz" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                        </div>
                    </div>

                </EntityArray>

                <div />

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Intenciones</label>
                    </div>
                </div>


                <EntityArray field='AnalyticsRulesIntentions' label='Intenciones'>
                    <RowView>
                        <div className="border border-light rounded-sm mt-2 p-3">
                            <div className="row mb-1">
                                <div className="col-xl-1">
                                    <label className="mt-2">Intención</label>
                                </div>
                                <div className="col-xl-2">
                                    <ComboField field='StrClaseIntencion' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analisis/intenciones' navigationPath='' placeHolder='Declare la intención que desea añadir a la regla' forceValidation={true} />
                                </div>
                                <div className="col-xl-3">
                                    <JsonEntity entity='JsonIntencion'>
                                        <div className="row mb-1">
                                            <div className="col-xl-3">
                                                <JsonKey />
                                            </div>
                                            <div className="col-xl-9">
                                                <JsonValue />
                                            </div>
                                        </div>
                                    </JsonEntity>
                                </div>
                                <div className="col-xl-1">
                                    <label className="mt-2">Porcentaje</label>
                                </div>
                                <div className="col-xl-1">
                                    <NumberField field='IntPercentage' />
                                </div>
                                {/*<div className="col-xl-1">
                                    <label className="mt-2">Proceso Manual</label>
                                </div>
                                <div className="col-xl-1 pt-2">
                                    <BooleanField field='BlnManualProcess' />
                                </div>*/}
                                <div className="col-xl-1">
                                    <label className="mt-2">Obligatoria</label>
                                </div>
                                <div className="col-xl-1 pt-2">
                                    <BooleanField field='BlnRequired' />
                                </div>
                                <div className="col-xl-1">
                                    <label className="mt-2">Peso</label>
                                </div>
                                <div className="col-xl-1">
                                    <NumberField field='IntPeso' />
                                </div>
                            </div>
                            <div />

                            
                        </div>
                    </RowView>
                    <div className="row mb-1">
                        <div className="col-xl-2">
                            <GridActionButton label="Añadir Intencion" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                        </div>
                    </div>

                </EntityArray>

                <div />

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Mappings</label>
                    </div>
                </div>

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <EntityArray field='AnalyticsRulesMappings' label='MAppings'>
                            <RowView>
                                <div className="border border-light rounded-sm mt-2 p-3">
                                    <div className="row mb-1">
                                        <div className="col-xl-1">
                                            <label className="mt-2">Origen</label>
                                        </div>
                                        <div className="col-xl-5">
                                            <ComboField field='StrOrigen' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analyticsrules/analyticsrules' listMethod='intenciones' navigationPath='' placeHolder='Declare la propiedad origen' forceValidation={true} />
                                        </div>
                                        <div className="col-xl-1">
                                            <label className="mt-2">Destino</label>
                                        </div>
                                        <div className="col-xl-5">
                                            <ComboField field='StrDestino' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analyticsrules/analyticsrules' listMethod='voces' navigationPath='' placeHolder='Declare la propiedad destino' forceValidation={true} />
                                        </div>
                                    </div>
                                </div>
                            </RowView>
                            <div className="row mb-1">
                                <div className="col-xl-2">
                                    <GridActionButton label="Añadir mapping" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                                </div>
                            </div>
                        </EntityArray>
                    </div>
                </div>

                <div />

                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Párrafo de resultados</label>
                    </div>
                </div>
                <div className="border border-light rounded-sm mt-2 p-3">
                    <EntityArray field='AnalyticsRulesSentences' label='Oraciones'>
                        <RowView>
                            <div className="row mb-1">
                                <div className="col-xl-12">
                                    <label>Frases</label>
                                </div>
                            </div>
                            <div className="border border-light rounded-sm mt-2 p-3">
                                <EntityArray field='AnalyticsRulesSentencesFrases' label='Frases'>
                                    <RowView>

                                        <div className="row mb-1">
                                            <div className="col-xl-5">
                                                <Field field='StrFrase' type='text' />
                                            </div>
                                            <div className="col-xl-3">
                                                <ComboField field='StrPropiedadSufijo' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/analyticsrules/analyticsrules' listMethod='resultados' navigationPath='' placeHolder='Declare la voz que desea añadir a la regla' forceValidation={true} />
                                            </div>
                                            <div className="col-xl-4">
                                                <Field field='StrFraseSufijo' type='text' />
                                            </div>
                                        </div>

                                    </RowView>
                                    <div className="row mb-1">
                                        <div className="col-xl-2">
                                            <GridActionButton label="Añadir frase" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                                        </div>
                                    </div>

                                </EntityArray>
                            </div>

                        </RowView>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <GridActionButton label="Añadir oración" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                            </div>
                        </div>

                    </EntityArray>
                </div>
                {/*
                <div className="row mb-1">
                    <div className="col-xl-12">
                        <label>Hitos</label>
                    </div>
                </div>
                
                <div className="border border-light rounded-sm mt-2 p-3">
                    <EntityArray field='AnalyticsRulesHitos' label='Hitos'>
                        <RowView>

                            <div className="row mb-1">
                                <div className="col-xl-6">
                                    <ComboField field='IntIdHito' listKeyField='IntId' listDisplayField='StrNombre' listController='api/config/hitos' navigationPath='/matilda/configuracion/hitos/item' placeHolder='Seleccione un elemento' />
                                </div>
                            </div>

                        </RowView>
                        <div className="row mb-1">
                            <div className="col-xl-2">
                                <GridActionButton label="Añadir Hito" icon="fas fa-plus" action="newRow" showInBody="false" className="btn btn-primary" />
                            </div>
                        </div>

                    </EntityArray>
                </div>*/}

                    
            </Form>

        );
    }

}

export default withRouter(Reglas_Item)
