import React, { Component } from 'react';
import { Route } from 'react-router';
import { withRouter} from 'react-router-dom';
import Documentos_Item from './Documentos_Item';
import { Panel, PanelHeader, PanelBody } from 'color_admin/components/panel/panel.jsx';
import { ReactTableDefaults } from 'react-table';
import "react-table/react-table.css";
import matchSorter from 'match-sorter'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Button } from 'primereact/button';


/*sobrescribir el componente de paginación*/
import Pagination from 'comun/components/Pagination';
Object.assign(ReactTableDefaults, {
    PaginationComponent: Pagination
});


class Documentos extends Component {

    constructor() {
        super();

        this.linkItem = "/matilda/documentos/item"
        this.controller = "api/documentos/documentos";
        this.dt = undefined;

    
        this.state = {
            textoBusqueda: '',
            resultadosBusqueda: [],
            resultadoSeleccionado: null,
            sorted: [],
            page: 0,
            pageSize: 10,
            expanded: {},
            resized: [],
            filtered: [],
            totalSize:0
        };

        this.tableColumns = [
            {
  
                columns: [
                    {
                        Header: "ID",
                        accessor: "IntId",
                        maxWidth: 150
                      }
                ]
            },
            {

                columns: [
                    {
                        Header: "Nombre",
                        accessor: "StrNombre",
                        filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, { keys: ["StrNombre"] }),
                        filterAll: true

                    }
                ]
            }
        
        ]


    }

    actualizarTextoBusqueda(strTexto) {
        this.setState({ textoBusqueda: strTexto });
    };

  
    seleccionarResultado(seleccion) {
        this.setState({ resultadoSeleccionado: seleccion });
        this.mostrarItem(seleccion["IntId"]);
    };

    mostrarItem(itemId) {
        this.props.history.push(this.linkItem + "/" + itemId);
    };



    async getResults() {
        this.loading = true;
        const response = await fetch(this.controller + '/filter?filter=' + this.state.textoBusqueda, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' +  localStorage.getItem('token')}
        });

        const data = await response.json();
        this.setState({ resultadosBusqueda: data });
        this.loading = false;

    }

    procesarBusqueda(key) {
        if (key === "Enter") {
            this.getResults();
        }
    }

    exportCSV() {
        if (this.dt !== undefined) {
            this.dt.exportCSV();
        }
    }    

    setDt(dt) {
        if (dt != null) {
            this.dt= dt;
        }
    }

        
    render() {

        
        const header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" label="Exportar" onClick={event => this.exportCSV()}></Button></div>;
        
        return (
            
            <div className="contenedor" >
                 <div > 
                         <Panel>
                            <PanelHeader>Buscador de documentos</PanelHeader>
                            <PanelBody>
                                <div className="input-group" >
                                    <input type="text" className="form-control" placeholder="Escriba un texto de búsqueda" value={this.state.textoBusqueda} onChange={event => this.actualizarTextoBusqueda(event.currentTarget.value)} />
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-blue fa fa-search" onClick={event => this.getResults()} />
                                        <button label="Nuevo" className="btn btn-blue fa fa-plus" onClick={event => this.mostrarItem(0)} />
                                    </div>
                                </div>
                               
                                <div className="m-t-5">
                                </div>

                                <DataTable value={this.state.resultadosBusqueda} ref={event => this.setDt(event)} className="p-datatable-striped" selection={this.state.resultadoSeleccionado} selectionMode="single" onSelectionChange={event => this.seleccionarResultado(event.value)} resizableColumns columnResizeMode="fit"
                                    paginator
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}" rows={10} rowsPerPageOptions={[5,10, 20, 50]}
                                    paginatorRight={header} scrollable scrollHeight="300px" sortMode="multiple" csvSeparator=";" >
                                    <Column field="IntId" header="ID" style={{ width: '20%' }} sortable filter filterPlaceholder="Buscar ID"></Column>
                                    <Column field="StrNombre" header='Nombre' sortable filter filterPlaceholder="Filtrar por nombre" filterMatchMode="contains"></Column>
                                    </DataTable>
                                                         
     
                            </PanelBody>
                        </Panel>
                </div>

                <div className="rellenar" >
                    <Panel className="contenedor">
                        <PanelHeader>Documento</PanelHeader>
                        <PanelBody className="rellenar" >
                                <Route path='/matilda/documentos/item/:itemId' component={Documentos_Item} />
                            </PanelBody>
                        </Panel>
                 </div>
             </div>
            

 
        );
    }

}


export default withRouter(Documentos)
