import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from "react-router-dom";

class DropdownProfile extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			dropdownOpen: false
        };
       this.handleClick = this.handleClick.bind(this);

	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
    }

    handleClick() {
        console.log("Token Borrado");
        localStorage.clear('token');
        return this.props.history.push('/matilda/pages/seguridad/Login-Matilda');
    }

  
    render() {

        const usuario = JSON.parse(localStorage.getItem('usuario'))

		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="dropdown navbar-user" tag="li">
				<DropdownToggle tag="a">
					<img src="../assets/color_admin/img/user/user-13.jpg" alt="" /> 
                    <span className="d-none d-md-inline">{(usuario !== null)?usuario["StrNombreCompleto"]:''}</span> <b className="caret"></b> {/*Título Menu Superior Derecha - Usuario  */}
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
					<DropdownItem>Edit Profile</DropdownItem>
					<DropdownItem><span className="badge badge-danger pull-right">2</span> Inbox</DropdownItem>
					<DropdownItem>Calendar</DropdownItem>
					<DropdownItem>Setting</DropdownItem>
					<div className="dropdown-divider"></div>
                    <DropdownItem onClick={this.handleClick}>Log Out</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		);
	}
};

export default withRouter(DropdownProfile);
