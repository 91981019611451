import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PageSettings } from 'color_admin/config/page-settings.js';
import Form from 'comun/components/Form';
import Field, { NumberField, BooleanField, DateField } from 'comun/components/Fields';
import ComboField from 'comun/components/ComboField';
import ActionButton from 'comun/components/ActionButton';
import UploadButton from 'comun/components/UploadButton';
import ActionButtonBackend from 'comun/components/ActionButtonBackend';
import ConditionalRender from 'comun/components/ConditionalRender';
import { Alert } from 'reactstrap';
import ReactPlayer from "react-player";

class RegisterV3 extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.context.handleSetPageSidebar(false);
        this.context.handleSetPageHeader(false);
        this.context.handleSetBodyWhiteBg(true);
    }

    componentWillUnmount() {
        this.context.handleSetPageSidebar(true);
        this.context.handleSetPageHeader(true);
        this.context.handleSetBodyWhiteBg(false);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.history.push('/dashboard/v3');
    }

    render() {
        return (
            <Form controller='api/config/solicitudesalta' title='' entity='SolicitudesAlta' itemId={this.props.match.params.itemId} key={this.props.match.params.itemId} parentProps={this.props} enabled={true} showButtons={false}>
                <div className="register register-with-news-feed">
                    <div className="news-feed">
                        {/*<div className="news-image" style={{ backgroundImage: 'url(/assets/color_admin/img/matilda/Matilda_procesando.png)', backgroundSize: 'auto', backgroundRepeat: 'no-repeat', backgroundSize: '50%' }}></div>*/}
                        <ReactPlayer url='/assets/color_admin/img/matilda/Matilda_procesandoBlanco_sm350x450.mp4' width="100%" height="100%" playing={true} muted={true} loop={true} controls={false} />
                        <div className="news-caption">
                            <h4 className="caption-title text-secondary"><b>MATILDA</b></h4>
                            <p>
                                ¡Hola! Desde EMC te damos la bienvenida a tu entorno de pruebas, soy Matilda,
                                un robot, y mi superpoder es que soy capaz de leer documentos judiciales e indicarte lo que dicen (muy rápido). ¿Quieres probarlo?
						    </p>
                        </div>
                    </div>
                    <div className="right-content pt-2">
                        <div className="brand mb-3">
                            {/*<img src="/assets/color_admin/img/logo/LogoEMC_b.png" width="180" height="80" />*/}
                            <img src="/assets/color_admin/img/logo/LOGO_EMC_DORADO_BANDERA_mod.png" width="250" height="89" />
                        </div>

                        <h1 className="register-header">
                            Registro
                            <small>Rellena los datos para solicitar tu cuenta de demostración</small>
                        </h1>
                        <div className="register-content">
                            <div className="row row-space-10">
                                <div className="col-md-6">
                                    <label className="control-label">Nombre <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-6">
                                    <label className="control-label">Apellidos <span className="text-danger">*</span></label>
                                </div>
                            </div>
                            <div className="row m-b-15">
                                <div className="col-md-6">
                                    <Field field='StrNombre' controlType='text' placeholder="Nombre" />
                                </div>
                                <div className="col-md-6">
                                    <Field field='StrApellidos' controlType='text' placeholder="Apellidos" />
                                </div>
                            </div>


                            <div className="row row-space-10">
                                <div className="col-md-6">
                                    <label className="control-label">Email <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-6">
                                    <label className="control-label">Compañia <span className="text-danger">*</span></label>
                                </div>
                            </div>
                            <div className="row m-b-15">
                                <div className="col-md-6">
                                    <Field field='StrEmail' controlType='text' placeholder="EMail" />
                                </div>
                                <div className="col-md-6">
                                    <Field field='StrEmpresa' controlType='text' placeholder="Compañia" />
                                </div>
                            </div>

                            {/*
                            <label className="control-label">Email <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                <Field field='StrEmail' controlType='text' placeholder="EMail" />
                                </div>
                            </div>
                            <label className="control-label">Compañia <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                <Field field='StrEmpresa' controlType='text' placeholder="Compañia" />
                                </div>
                            </div>
                            */}

                            <label className="control-label">Actividad <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                    <ComboField field='StrActividad' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/config/solicitudesalta' listMethod='actividades' navigationPath='' placeHolder='Seleccione la actividad de su empresa' forceValidation={true} />
                                </div>
                            </div>
                            <label className="control-label">Cuantas notificaciones gestiona anualmente? <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                <ComboField field='StrCantidadNotificaciones' listKeyField='StrNombreCompleto' listDisplayField='StrNombre' listController='api/config/solicitudesalta' listMethod='cantidadresoluciones' navigationPath='' placeHolder='Seleccione cuantas resoluciones procesa anualmente' forceValidation={true} />
                                </div>
                            </div>

                            {/*
                            <label className="control-label">Contraseña <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                <Field field='StrPassword' controlType='password' placeholder="Contraseña" />
                                </div>
                            </div>
                            <label className="control-label">Repita contraseña <span className="text-danger">*</span></label>
                            <div className="row m-b-15">
                                <div className="col-md-12">
                                <Field field='StrConfirmarPassword' controlType='password' placeholder="Repetir contraseña" />
                                </div>
                            </div>
                            */}
                            <div className="row row-space-10">
                                <div className="col-md-6">
                                    <label className="control-label">Contraseña <span className="text-danger">*</span></label>
                                </div>
                                <div className="col-md-6">
                                    <label className="control-label">Repita contraseña <span className="text-danger">*</span></label>
                                </div>
                            </div>
                            <div className="row m-b-15">
                                <div className="col-md-6">
                                    <Field field='StrPassword' controlType='password' placeholder="Contraseña" />
                                </div>
                                <div className="col-md-6">
                                    <Field field='StrConfirmarPassword' controlType='password' placeholder="Repetir contraseña" />
                                </div>
                            </div>

                            <div className="">
                                <div className="">
                                    <label className="mt-2 mr-2">Acepto políticas y condiciones de uso</label><BooleanField field='BlnAceptaPolitica' />
                                    <label htmlFor="agreement_checkbox">
										Al pulsar 'Registrarme', aceptas nuestras <Link to="/user/register-v3">Condiciones de uso</Link> y nuestra <Link to="/user/register-v3">Política de privacidad</Link>, incluyendo el <Link to="/user/register-v3">Uso de Cookies</Link>.
									</label>
                                </div>
                            </div>

                            {/*
                             * este es el original del template pero no funciona con el control checkbox q estamos usando... pendiente fix
                            <div class="checkbox checkbox-css m-b-30">
                                <div class="checkbox checkbox-css m-b-30">
                                    <BooleanField field='BlnAceptaPolitica' />
                                    <label htmlFor="agreement_checkbox">
                                        Al pulsar 'Registrarme', aceptas nuestras <Link to="/user/register-v3">Condiciones de uso</Link> y nuestra <Link to="/user/register-v3">Política de privacidad</Link>, incluyendo el <Link to="/user/register-v3">Uso de Cookies</Link>.
                                    </label>
                                </div>
                            </div>
                            */}

                            <ConditionalRender field='IntEstado' notEqualValue={1}>
                                {/*
                                <div className="row m-b-15">
                                    <div className="col-md-12">
                                        <ActionButtonBackend label="Registrarme" action="enviando" className="btn btn btn-primary" />
                                    </div>
                                </div>
                                */}
                                <div className="login-buttons">
                                    {/*<button type="submit" className="btn btn-primary btn-block btn-lg">Entrar</button>*/}
                                    <ActionButtonBackend label="Registrarme" action="enviando" className="btn btn btn-primary btn-block btn-lg" />
                                </div>
                            </ConditionalRender>



                            <ConditionalRender field='IntEstado' equalValue={1}>
                                <div className="row m-b-15">
                                    <div className="col-md-12">
                                    <Field field='StrMensaje' controlType='text' placeholder="" fieldClass="alert alert-success fade show" />
                                    </div>
                                </div>
                            </ConditionalRender>

                            <ConditionalRender field='IntEstado' equalValue={2}>
                                <div className="row m-b-15">
                                    <div className="col-md-12">
                                        <Field field='StrMensaje' controlType='text' placeholder="" fieldClass="alert alert-danger fade show" />
                                    </div>
                                </div>
                            </ConditionalRender>

                            <div className="m-t-20 m-b-40 p-b-40 text-inverse">
								¿Ya tienes cuenta? Haz click <Link to="/matilda/pages/seguridad/Login-Matilda">aquí</Link> para entrar.
							</div>
                            <hr />
                            <p className="text-center">
                                &copy; EMC Software Jurídico - Todos los derechos reservados 2021
							</p>
                            
                        </div>
                    </div>
                    </div>
                </Form>
        )
    }
}

export default withRouter(RegisterV3);
